import React from "react";
import "./SetUp.css";
import NavLinkData from "../NavLinkData/NavLinkData";

export default function SetUp() {
  const labAdminLinks = [
    { img: "tech.png", link: "/lab/recipes", linkName: "Techs" },
  ];

  const farmAdminLinks = [
    { img: "cow.png", link: "/farmadmin/cow", linkName: "Cows" },
    { img: "machine.png", link: "/lab", linkName: "Machines" },
    { img: "tech.png", link: "/", linkName: "Techs" },
  ];

  return (
    <div className="SetUp">
      <div className="NavButtons row">
        {(localStorage.getItem("isLabAdmin") === "true" ||
          localStorage.getItem("isLabUserandAdmin") === "true") &&
          labAdminLinks.map((data, index) => (
            <NavLinkData key={index} data={data} index={index}></NavLinkData>
          ))}
        {(localStorage.getItem("isFarmAdmin") === "true" ||
          localStorage.getItem("isFarmUserandAdmin") === "true") &&
          farmAdminLinks.map((data, index) => (
            <NavLinkData key={index} data={data} index={index}></NavLinkData>
          ))}
      </div>
    </div>
  );
}

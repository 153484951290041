import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import Logo from "./images/omeat_green.png";
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./lib/contextLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onError } from "./lib/errorLib";

function App() {
  const history = useHistory();

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    localStorage.clear();
    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App container-fluid py-3">
        <Navbar
          collapseOnSelect
          expand="md"
          className="mb-3 color-nav fixed-top  shadow-sm "
        >
          <LinkContainer to={"/"}>
            <Navbar.Brand className="font-weight-bold text-muted logoImage">
              <img
                src={Logo}
                width="30"
                height="30"
                className="d-inline-block align-top logoImage"
                alt=" "
              />
            </Navbar.Brand>
          </LinkContainer>
          {" "}
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                <>
                  {localStorage.getItem("isFarmAdmin") === "true" ||
                  localStorage.getItem("isFarmUserandAdmin") === "true" ? (
                    <>
                      <LinkContainer
                        className="navItems font-color"
                        to="/farmadmin/cow"
                      >
                        <Nav.Link>Cows</Nav.Link>
                      </LinkContainer>
                      <LinkContainer
                        className="navItems font-color"
                        to="/admin"
                      >
                        <Nav.Link>Machines</Nav.Link>
                      </LinkContainer>
                      <LinkContainer
                        className="navItems font-color"
                        to="/admin"
                      >
                        <Nav.Link>Techs</Nav.Link>
                      </LinkContainer>
                    </>
                  ) : null}
                  {localStorage.getItem("isLabAdmin") === "true" ||
                  localStorage.getItem("isLabUserandAdmin") === "true" ? (
                    <>
                      <LinkContainer
                        className="navItems font-color"
                        to="/admin"
                      >
                        <Nav.Link>Techs</Nav.Link>
                      </LinkContainer>
                    </>
                  ) : null}
                  {localStorage.getItem("userdepartment") === "lab" ||
                  localStorage.getItem("isLabUserandAdmin") === "true" ? (
                    <>
                      <LinkContainer className="navItems font-color" to="/lab">
                        <Nav.Link>Lab</Nav.Link>
                      </LinkContainer>
                      <LinkContainer
                        className="navItems font-color"
                        to="/lab/recipes"
                      >
                        <Nav.Link>Media Recipe</Nav.Link>
                      </LinkContainer>
                      <LinkContainer
                        className="navItems font-color"
                        to="/lab/AX1M"
                      >
                        <Nav.Link>AX1M</Nav.Link>
                      </LinkContainer>
                      
                      <LinkContainer className="navItems font-color" to="/lab/delivery">
                        <Nav.Link>Delivery Point</Nav.Link>
                      </LinkContainer>
                    </>
                  ) : null}
                  {localStorage.getItem("userdepartment") === "farm" ||
                  localStorage.getItem("isFarmUserandAdmin") === "true" ? (
                    <>
                      <LinkContainer
                        className="navItems font-color"
                        to="/farm/barrel"
                      >
                        <Nav.Link>Barrel</Nav.Link>
                      </LinkContainer>
                      <LinkContainer className="navItems font-color" to="/farm">
                        <Nav.Link>Farm</Nav.Link>
                      </LinkContainer>
                      <LinkContainer
                        className="navItems font-color"
                        to="/blood"
                      >
                        <Nav.Link>Blood</Nav.Link>
                      </LinkContainer>                   
                      <LinkContainer
                        className="navItems font-color"
                        to="/biopsy"
                      >
                        <Nav.Link>Biopsy</Nav.Link>     
                        </LinkContainer>                         
                      <LinkContainer className="navItems font-color" to="/farm/shipping">
                        <Nav.Link>Shipping</Nav.Link>
                      </LinkContainer>
                    </>
                  ) : null}
                  <Nav.Link
                    className="navItems font-color"
                    onClick={handleLogout}
                  >
                    Logout
                  </Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer className="navItems font-color" to="/signup">
                    <Nav.Link>Signup</Nav.Link>
                  </LinkContainer>
                  <LinkContainer className="navItems font-color" to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <Routes />
        </AppContext.Provider>
      </div>
    )
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { Auth, API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import { FloatingLabel, Modal } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";

export default function Entries() {
  const { id } = useParams();
  const history = useHistory();
  const [entry, setEntry] = useState(null);
  const [Title, setTitle] = useState("");
  const [cowNames, setCowNameList] = useState([]);
  const [CowName, setCowName] = useState("");
  const [CollectionDate, setCollectionDate] = useState("");
  const [StartTimeCow, setStartTimeCow] = useState("");
  //const [StartTimeApheresis, setStartTimeApheresis] = useState("");
  //const [EndTimeApheresis, setEndTimeApheresis] = useState("");
  // const [CollectionVolume, setCollectionVolume] = useState("");
  const [LeadTech, setLeadTech] = useState("");
  const [EndTimeCow, setEndTimeCow] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [Notes, setNotes] = useState("");
  const [QRCodeLinked, setQRCodeLinked] = useState("");
  const [QRshow, setQRshow] = useState(false);
  const [showError, setErrorShow] = useState(false);

  const handleClose = () => setQRshow(false);
  const handleShow = (i) => {
    setCurrentQRNum(i);
    setscannerErrorMessage(false);
    setScannerCustomErrorMessage(false);
    setQRshow(true);
  };

  const handleErrorClose = () => setErrorShow(false);

  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const [scannerCustomErrorMessage, setScannerCustomErrorMessage] =
    useState(false);
  const [curUserID, setcurUserID] = useState("");

  // const [AX1ID, setAX1ID] = useState("");
  // const [AX1SK, setAX1SK] = useState("");
  // const [AX1PK, setAX1PK] = useState("");
  const [currentQRNum, setCurrentQRNum] = useState(0);

  console.log(curUserID);

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      const bloodQR = QRCodeLinked.split(":");
      console.log(bloodQR);
      console.log(currentQRNum);
      // if (currentQRNum === 0 && bloodQR[0].toUpperCase() === "BLD") {
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0) {
        if (scannedID[0].GSI1SK) {
          setScannerCustomErrorMessage(true);
        }
      } else {
        setQRCodeLinked(QRCodeLinked);
        handleClose();
      }
      // } else if (currentQRNum === 1) {
      //   const scannedID = await API.get(
      //     "omeat",
      //     `/scan/entries/${QRCodeLinked}`
      //   );
      //   console.log(scannedID);
      //   if (scannedID.length > 0) {
      //     setAX1ID(QRCodeLinked);
      //     setAX1PK(scannedID[0].pk)
      //     setAX1SK(scannedID[0].sk)
      //     handleClose();
      //   }
      // } else {
      //   setScannerCustomErrorMessage(true);
      // }
    }
  }
  async function getCows() {
    const cowID = encodeURIComponent("FARM#SETUP$DRAKE#COWS");
    return await API.get("omeat", `/FARM/SETUP/${cowID}`);
  }

  useEffect(() => {
    function loadEntry() {
      //console.log("ID:" + id);
      //return API.get("omeat", `/BIOPSY/entries/${id.replace(/#/g,"__")}`);
      return API.get("omeat", `/BIOPSY/entries/${id}`);
    }

    async function onLoad() {
      try {
        const entry = await loadEntry();
        const CurrentUserID = await Auth.currentUserInfo();
        const cows = await getCows();
        setCowNameList(cows);
        console.log("CurrentUserID:" + CurrentUserID.id, entry.sk);
        setcurUserID(CurrentUserID.id);
        setTitle(entry.Title);
        setCowName(entry.CowName);
        setCollectionDate(entry.CollectionDate);
        setStartTimeCow(entry.StartTimeCow);
        //setStartTimeApheresis(entry.StartTimeApheresis);
        //setEndTimeApheresis(entry.EndTimeApheresis);
        // setCollectionVolume(entry.CollectionVolume);
        setLeadTech(entry.LeadTech);

        setEndTimeCow(entry.EndTimeCow);
        setNotes(entry.Notes);
        setQRCodeLinked(entry.QRCodeLinked);
        // setAX1ID(entry.AX1ID);
        // setAX1SK(entry.AX1SK);
        // setAX1PK(entry.AX1PK);

        setEntry(entry);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return 1;
  }

  function updateEntry(Item) {
    return API.put("omeat", `/BIOPSY/entries/${encodeURIComponent(id)}`, {
      body: Item,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    await updateEntry({
      Title,
      QRCodeLinked,
      CowName,
      CollectionDate,
      StartTimeCow,
      //StartTimeApheresis,
      //EndTimeApheresis,
      // CollectionVolume,
      LeadTech,
      EndTimeCow,
      Notes,
      // AX1ID,
      // AX1SK,
      // AX1PK,
    });

    setIsLoading(false);
    history.push("/biopsy");
  }

  return (
    <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
      {entry && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="Title">
            <FloatingLabel
              controlId="floatingInput"
              label="Title"
              className="mb-3"
            >
              <Form.Control
                placeholder="Title"
                type="text"
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="Scanner">
            <>
              <button type="button" onClick={handleShow}>
                <BsUpcScan size={30} />
              </button>

              <p>{QRCodeLinked}</p>
            </>
          </Form.Group>
          <Form.Group controlId="QRCodeLinked">
            <FloatingLabel
              controlId="floatingInput"
              label="QRCodeLinked"
              className="mb-3"
            >
              <Form.Control
                placeholder="QRCodeLinked"
                disabled
                type="text"
                value={QRCodeLinked}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CowName">
            <FloatingLabel
              controlId="floatingInput"
              label="Cow Name"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                value={CowName}
                aria-label="Default select example"
                onChange={(e) => setCowName(e.target.value)}
              >
                <option> </option>
                {cowNames.map((name, index) => (
                  <option key={index} value={name} selected={name === CowName}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CollectionDate">
            <FloatingLabel
              controlId="floatingInput"
              label="Collection Date"
              className="mb-3"
            >
              <Form.Control
                type="date"
                name="duedate"
                value={CollectionDate}
                placeholder="Due date"
                onChange={(e) => setCollectionDate(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="StartTimeCow">
            <FloatingLabel
              controlId="floatingInput"
              label="Start time (Cows in place)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Start time (Cows in place)"
                value={StartTimeCow}
                type="time"
                onChange={(e) => setStartTimeCow(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          {/*<Form.Group controlId="StartTimeApheresis">
            <FloatingLabel
              controlId="floatingInput"
              label="Start time running apheresis machine"
              className="mb-3"
            >
              <Form.Control
                placeholder="Start time running apheresis machine"
                type="time"
                value={StartTimeApheresis}
                onChange={(e) => setStartTimeApheresis(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="EndTimeApheresis">
            <FloatingLabel
              controlId="floatingInput"
              label="End time running apheresis machine"
              className="mb-3"
            >
              <Form.Control
                placeholder="End time running apheresis machine"
                type="time"
                value={EndTimeApheresis}
                onChange={(e) => setEndTimeApheresis(e.target.value)}
              />
            </FloatingLabel>
                </Form.Group>*/}
          <Form.Group controlId="EndTimeCow">
            <FloatingLabel
              controlId="floatingInput"
              label="End time (Cow release)"
              className="mb-3"
            >
              <Form.Control
                placeholder="End time (Cow release)"
                type="time"
                value={EndTimeCow}
                onChange={(e) => setEndTimeCow(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          {/* <Form.Group controlId="CollectionVolume">
            <FloatingLabel
              controlId="floatingInput"
              label="Collection Vol (Litres)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Collection Vol (Litres)"
                type="number"
                step="0.01"
                value={CollectionVolume}
                onChange={(e) => setCollectionVolume(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group> */}
          <Form.Group controlId="LeadTech">
            <FloatingLabel
              controlId="floatingInput"
              label="Lead Tech"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                value={LeadTech}
                aria-label="Default select example"
                onChange={(e) => setLeadTech(e.target.value)}
              >
                <option> </option>
                <option value="Yazmin">Yazmin</option>
                <option value="Anessa">Anessa</option>
                <option value="Alex">Alex</option>
                <option value="Lisa">Lisa</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="Notes">
            <FloatingLabel
              controlId="floatingInput"
              label="Notes"
              className="mb-3"
            >
              <Form.Control
                placeholder="Notes"
                type="text"
                value={Notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="QRCodeLinked">
            <FloatingLabel
              controlId="floatingInput"
              label="Shipped Date and Time"
              className="mb-3"
            >
              <Form.Control
                placeholder="QRCodeLinked"
                disabled
                type="text"
                value={`${entry["shipDate"] ? new Date(entry["shipDate"]).toLocaleDateString() : ""} ${
                  entry["shipTime"] ? entry["shipTime"] : ""
                } `}
              />
            </FloatingLabel>
          </Form.Group>

          <Form.Group controlId="QRCodeLinked">
            <FloatingLabel
              controlId="floatingInput"
              label="Recieved Date and Time"
              className="mb-3"
            >
              <Form.Control
                placeholder="QRCodeLinked"
                disabled
                type="text"
                value={`${entry["recievedDate"] ? new Date(entry["recievedDate"]).toLocaleDateString() : ""} ${
                  entry["recievedTime"] ? entry["recievedTime"] : ""
                } `}
              />
            </FloatingLabel>
          </Form.Group>
          {/* <Container className="scannerC">
            <Row>
              <Col className="scanner">
                <Form.Group controlId="AX1ID">
                  <FloatingLabel controlId="floatingInput" label="AX1ID">
                    <Form.Control
                      placeholder="AX1ID"
                      type="text"
                      disabled
                      value={AX1ID}
                      onChange={(e) => setAX1ID(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={2} md={1}>
                <button
                  type="button"
                  // disabled={AX1ID}
                  onClick={() => handleShow(1)}
                >
                  <BsUpcScan size={15} />
                </button>
              </Col>
            </Row>
          </Container> */}
          <LoaderButton
            block="true"
            type="submit"
            size="lg"
            variant="primary"
            isLoading={isLoading}
            className="format"
            disabled={!validateForm()}
          >
            Update
          </LoaderButton>
        </Form>
      )}
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                await loadScannedEntry(result.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {scannerErrorMessage ? (
            <div className="justify-content-center alert alert-danger">
              Scanned Item already exists in the database!
            </div>
          ) : null}
          {scannerCustomErrorMessage ? (
            <div className="justify-content-center alert alert-danger">
              Please scan proper blood QR!
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
      <Modal
        show={showError}
        onHide={handleErrorClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center alert alert-danger">
            Please upload 'CSV' file!
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
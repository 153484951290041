import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
// import config from "../../config";
import "./CreateBiopsyEntry.css";
import { FloatingLabel, Modal } from "react-bootstrap";
import { API } from "aws-amplify";
// import { s3Upload } from "../../lib/awsLib";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import { onError } from "../../lib/errorLib";
// import Select from "react-select";

export default function CreateEntry() {
  // const file = useRef(null);
  // const file1 = useRef(null);
  // const file2 = useRef(null);
  // const file3 = useRef(null);
  const history = useHistory();
  let d = new Date();
  const colDate = d.toISOString().split("T")[0];
  const [Title, setTitle] = useState("");
  const [cowNames, setCowNameList] = useState([]);
  const [CowName, setCowName] = useState("");
  const [CollectionDate, setCollectionDate] = useState(colDate);
  const [StartTimeCow, setStartTimeCow] = useState("");
  //const [StartTimeApheresis, setStartTimeApheresis] = useState("");
  //const [EndTimeApheresis, setEndTimeApheresis] = useState("");
  const [EndTimeCow, setEndTimeCow] = useState("");
  // const [MachineErrors, setMachineErrors] = useState("");
  // const [CowStress, setCowStress] = useState("");
  // const [NewWashedLine, setNewWashedLine] = useState("");
  // const [CollectionVolume, setCollectionVolume] = useState("");
  const [LeadTech, setLeadTech] = useState("");
  // const [CatheterPosition, setCatheterPosition] = useState("");
  // const [Machine, setMachine] = useState("");
  // const [Feeding, setFeeding] = useState(false);
  // const [BiopsyWorkToday, setBiopsyWorkToday] = useState(false);
  // const [cowWashed, setcowWashed] = useState(false);
  const [Notes, setNotes] = useState("");
  const [QRCodeLinked, setQRCodeLinked] = useState("");
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const [scannerCustomErrorMessage, setScannerCustomErrorMessage] =
    useState(false);
  // const [showError, setErrorShow] = useState(false);

  // const [TimeLoss, setTimeLoss] = useState("");
  // const [AnimalBehaviour, setAnimalBehaviour] = useState([]);
  // const [TeviotAnticoagulanat, setTeviotAnticoagulanat] = useState("");

  // const [TimeLoss, setTimeLoss] = useState("");
  // const [AnimalBehaviour, setAnimalBehaviour] = useState([]);
  // const [TeviotAnticoagulanat, setTeviotAnticoagulanat] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [QRshow, setQRshow] = useState(false);
  // const [AX1ID, setAX1ID] = useState("");
  // const [AX1SK, setAX1SK] = useState("");
  // const [AX1PK, setAX1PK] = useState("");

  const [currentQRNum, setCurrentQRNum] = useState(0);

  const handleClose = () => setQRshow(false);
  const handleShow = (i) => {
    setCurrentQRNum(i);
    setscannerErrorMessage(false);
    setScannerCustomErrorMessage(false);
    setQRshow(true);
  };

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      const bloodQR = QRCodeLinked.split(":");
      console.log(bloodQR);
      console.log(currentQRNum);
      // if (currentQRNum === 0 && bloodQR[0].toUpperCase() === "BLD") {
      //   const scannedID = await API.get(
      //     "omeat",
      //     `/scan/entries/${QRCodeLinked}`
      //   );
      //   if (scannedID.length > 0) {
      //     if (scannedID[0].GSI1SK) {
      //       setScannerCustomErrorMessage(true);
      //     }
      //   } else {
      //     setQRCodeLinked(QRCodeLinked);
      //     handleClose();
      //   }
      // } else if (currentQRNum === 1) {
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      console.log(scannedID);
      if (scannedID.length > 0) {
        // setAX1ID(QRCodeLinked);
        // setAX1PK(scannedID[0].pk)
        // setAX1SK(scannedID[0].sk)
        handleClose();
      } else {
        setQRCodeLinked(QRCodeLinked);
        handleClose();
      }
      // } else {
      //   setScannerCustomErrorMessage(true);
      // }
      console.log("scanned! now check if it exists ");
    }
  }
  async function getCows() {
    const cowID = encodeURIComponent("FARM#SETUP$DRAKE#COWS");
    return await API.get("omeat", `/FARM/SETUP/${cowID}`);
  }
  useEffect(() => {
    async function onLoad() {
      try {
        const cows = await getCows();
        setCowNameList(cows);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }
    onLoad();
  }, []);

  function createEntry(Item) {
    return API.post("omeat", "/BIOPSY/entries", {
      body: Item,
    });
  }

  function validateForm() {
    return CowName.length > 0 && QRCodeLinked.length > 0;
  }

  // function handleFileChange(event) {
  //   file.current = event.target.files[0];
  // }

  // function handleFileChange1(event) {
  //   file1.current = event.target.files[0];
  // }

  // function handleFileChange2(event) {
  //   file2.current = event.target.files[0];
  // }

  // function handleFileChange3(event) {
  //   file3.current = event.target.files[0];
  // }

  // const onSwitchFeeding = () => {
  //   setFeeding(!Feeding);
  // };
  // const onSwitchBiopsyWork = () => {
  //   setBiopsyWorkToday(!BiopsyWorkToday);
  // };
  // const onSwitchcowWashed = () => {
  //   setcowWashed(!cowWashed);
  // };
  async function handleSubmit(event) {
    console.log("Now submitting form");
    event.preventDefault();

    // if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
    //   alert(
    //     `Please pick a file smaller than ${
    //       config.MAX_ATTACHMENT_SIZE / 1000000
    //     } MB.`
    //   );
    //   return;
    // }
    // if (file1.current && file1.current.size > config.MAX_ATTACHMENT_SIZE) {
    //   alert(
    //     `Please pick a file smaller than ${
    //       config.MAX_ATTACHMENT_SIZE / 1000000
    //     } MB.`
    //   );
    //   return;
    // }
    // if (file2.current && file2.current.size > config.MAX_ATTACHMENT_SIZE) {
    //   alert(
    //     `Please pick a file smaller than ${
    //       config.MAX_ATTACHMENT_SIZE / 1000000
    //     } MB.`
    //   );
    //   return;
    // }
    // if (file3.current && file3.current.size > config.MAX_ATTACHMENT_SIZE) {
    //   alert(
    //     `Please pick a file smaller than ${
    //       config.MAX_ATTACHMENT_SIZE / 1000000
    //     } MB.`
    //   );
    //   return;
    // }
    setIsLoading(true);
    // const attachment = file.current ? await s3Upload(file.current) : null;
    // const attachment2 = file1.current ? await s3Upload(file1.current) : null;
    // const attachment3 = file2.current ? await s3Upload(file2.current) : null;
    // const attachment4 = file3.current ? await s3Upload(file3.current) : null;

    await createEntry({
      Title,
      CowName,
      CollectionDate,
      StartTimeCow,
      //StartTimeApheresis,
      //EndTimeApheresis,
      EndTimeCow,
      // MachineErrors,
      // CowStress,
      // NewWashedLine,
      // CollectionVolume,
      LeadTech,
      // CatheterPosition,
      // Machine,
      // Feeding,
      // BiopsyWorkToday,
      // cowWashed,
      // attachment,
      // attachment2,
      // attachment3,
      // attachment4,
      Notes,
      QRCodeLinked,
      // AX1ID,
      // AX1SK,
      // AX1PK,
      // TimeLoss,
      // AnimalBehaviour,
      // TeviotAnticoagulanat,
    });

    setIsLoading(false);
    history.push("/");
  }

  // const options = [
  //   { value: "thrashed", label: "thrashed" },
  //   { value: "calm", label: "calm" },
  //   { value: "caused_injury_to_self", label: "caused_injury_to_self" },
  //   { value: "extermely_reactive", label: "extermely_reactive" },
  //   { value: "moderately_reactive", label: "moderately_reactive" },
  //   { value: "slightly_reactive", label: "slightly_reactive" },
  //   { value: "collapsed", label: "collapsed" },
  //   { value: "relaxed", label: "relaxed" },
  //   { value: "chewing_cud", label: "chewing_cud" },
  //   { value: "did_not_want_put_head_through", label: "did_not_want_put_head_through" },
  //   { value: "non_reactive", label: "non_reactive" },
  //   { value: "being_naughty", label: "being_naughty" },
  //   { value: "laid_down_during_collection", label: "laid_down_during_collection" },
  //   { value: "vocalization", label: "vocalization" },

  // ];
  return (
    <>
      <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="Title">
            <FloatingLabel
              controlId="floatingInput"
              label="Title"
              className="mb-3"
            >
              <Form.Control
                placeholder="Title"
                type="text"
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="Scanner">
            <>
              <button type="button" onClick={() => handleShow(0)}>
                <BsUpcScan size={30} />
              </button>

              <p>{QRCodeLinked}</p>
            </>
          </Form.Group>
          <Form.Group controlId="QRCodeLinked">
            <FloatingLabel
              controlId="floatingInput"
              label="QRCodeLinked"
              className="mb-3"
            >
              <Form.Control
                placeholder="QRCodeLinked"
                disabled
                type="text"
                value={QRCodeLinked}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CowName">
            <FloatingLabel
              controlId="floatingInput"
              label="Cow Name"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                aria-label="Default select example"
                onChange={(e) => setCowName(e.target.value)}
              >
                <option> </option>
                {cowNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CollectionDate">
            <FloatingLabel
              controlId="floatingInput"
              label="Collection Date"
              className="mb-3"
            >
              <Form.Control
                type="date"
                name="duedate"
                placeholder="Due date"
                onChange={(e) => setCollectionDate(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="StartTimeCow">
            <FloatingLabel
              controlId="floatingInput"
              label="Start time (Cows in place)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Start time (Cows in place)"
                type="time"
                onChange={(e) => setStartTimeCow(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          {/*<Form.Group controlId="StartTimeApheresis">
            <FloatingLabel
              controlId="floatingInput"
              label="Start time running apheresis machine"
              className="mb-3"
            >
              <Form.Control
                placeholder="Start time running apheresis machine"
                type="time"
                onChange={(e) => setStartTimeApheresis(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="EndTimeApheresis">
            <FloatingLabel
              controlId="floatingInput"
              label="End time running apheresis machine"
              className="mb-3"
            >
              <Form.Control
                placeholder="End time running apheresis machine"
                type="time"
                onChange={(e) => setEndTimeApheresis(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>*/}
          <Form.Group controlId="EndTimeCow">
            <FloatingLabel
              controlId="floatingInput"
              label="End time (Cow release)"
              className="mb-3"
            >
              <Form.Control
                placeholder="End time (Cow release)"
                type="time"
                onChange={(e) => setEndTimeCow(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="LeadTech">
            <FloatingLabel
              controlId="floatingInput"
              label="Lead Tech"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                aria-label="Default select example"
                onChange={(e) => setLeadTech(e.target.value)}
              >
                <option> </option>
                <option value="Yazmin">Yazmin</option>
                <option value="Anessa">Anessa</option>
                <option value="Alex">Alex</option>
                <option value="Lisa">Lisa</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          {/* <Form.Group controlId="CowStress">
            <FloatingLabel
              controlId="floatingInput"
              label="Cow Stress (Percentage)"
              className="mb-3"
            >
              <Form.Control
                placeholder="CowStress"
                type="number"
                onChange={(e) => setCowStress(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="MachineErrors">
            <FloatingLabel
              controlId="floatingInput"
              label="MachineErrors"
              className="mb-3"
            >
              <Form.Control
                placeholder="MachineErrors"
                type="text"
                onChange={(e) => setMachineErrors(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="NewWashedLine">
            <FloatingLabel
              controlId="floatingInput"
              label="NewWashedLine"
              className="mb-3"
            >
              <Form.Control
                placeholder="NewWashedLine"
                type="text"
                onChange={(e) => setNewWashedLine(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CollectionVolume">
            <FloatingLabel
              controlId="floatingInput"
              label="Collection Vol (Litres)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Collection Vol (Litres)"
                type="number"
                step="0.01"
                onChange={(e) => setCollectionVolume(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="LeadTech">
            <FloatingLabel
              controlId="floatingInput"
              label="Lead Tech"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                aria-label="Default select example"
                onChange={(e) => setLeadTech(e.target.value)}
              >
                <option> </option>
                <option value="Yazmin">Yazmin</option>
                <option value="Anessa">Anessa</option>
                <option value="Alex">Alex</option>
                <option value="Lisa">Lisa</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CatheterPosition">
            <FloatingLabel
              controlId="floatingInput"
              label="Catheter Position"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                aria-label="Default select example"
                onChange={(e) => setCatheterPosition(e.target.value)}
              >
                <option> </option>
                <option value="Right Jugular">Right Jugular</option>
                <option value="Left Jugular">Left Jugular</option>
                <option value="RH">RH</option>
                <option value="LH">LH</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="Machine">
            <FloatingLabel
              controlId="floatingInput"
              label="Machine"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                aria-label="Default select example"
                onChange={(e) => setMachine(e.target.value)}
              >
                <option> </option>
                <option value="TT09D310 [C]">TT09D310 [C]</option>
                <option value="TT07G149 [A]">TT07G149 [A]</option>
                <option value="TT07G149 [A]">TT07G149 [D]</option>
                <option value="TT07G149">TT07G149</option>
                <option value="TT14B687">TT14B687</option>
                <option value="TT14B673">TT14B673</option>
                <option value="TT15k800">TT15k800</option>
                <option value="TT14B682">TT14B682</option>
                <option value="TT14B674">TT14B674</option>
                <option value="TT14B595">TT14B595</option>
                <option value="TT14K753">TT14K753</option>
                <option value="OMPROTO1">OMPROTO1</option>
                <option value="OMPROTO2">OMPROTO2</option>
                <option value="TT13F204">TT13F204</option>
                <option value="TT14G061">TT14G061</option>
                <option value="TT14K773">TT14K773</option>
                <option value="TT14B680">TT14B680</option>
                <option value="TT14B690">TT14B690</option>
                <option value="TT14B630">TT14B630</option>
                <option value="TT13F204">TT13F204</option>
                <option value="TT14C250">TT14C250</option>
                <option value="TT14C252">TT14C252</option>
                <option value="TT13M069">TT13M069</option>
                <option value="TT14C158">TT14C158</option>
                <option value="TT14B673">TT14B673</option>
                <option value="TT14B687">TT14B687</option>
                <option value="TT14B682">TT14B682</option>
                <option value="TT09D310">TT09D310</option>
                <option value="TT14L736">TT14L736</option>
                <option value="TT18F142">TT18F142</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mt-3" controlId="Feeding">
            <Form.Check
              type="switch"
              id="Feeding"
              label="Feeding?"
              className="mb-3"
              onChange={onSwitchFeeding}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="BiopsyWorkToday">
            <Form.Check
              type="switch"
              id="BiopsyWorkToday"
              label="Biopsy Work Today?"
              className="mb-3"
              onChange={onSwitchBiopsyWork}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="cowWashed">
            <Form.Check
              type="switch"
              id="cowWashed"
              label="Cow Washed Today?"
              className="mb-3"
              onChange={onSwitchcowWashed}
            />
          </Form.Group> */}
          <Form.Group controlId="Notes">
            <FloatingLabel
              controlId="floatingInput"
              label="Notes"
              className="mb-3"
            >
              <Form.Control
                placeholder="Notes"
                type="textarea"
                value={Notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          {/* <Form.Group controlId="file">
            <Form.Label>Attachment</Form.Label>
            <Form.Control onChange={handleFileChange} type="file" />
          </Form.Group>
          <Form.Group controlId="file">
            <Form.Label>Attachment2</Form.Label>
            <Form.Control onChange={handleFileChange1} type="file" />
          </Form.Group>
          <Form.Group controlId="file">
            <Form.Label>CBC Biopsy Test (Upload CSV) </Form.Label>
            <Form.Control onChange={handleFileChange2} type="file" />
          </Form.Group>
          <Form.Group controlId="file">
            <Form.Label>Chemistry Biopsy Test (Upload CSV)</Form.Label>
            <Form.Control onChange={handleFileChange3} type="file" />
          </Form.Group> */}
          <br />
          {/* <Form.Group controlId="TimeLoss">
            <FloatingLabel
              controlId="floatingInput"
              label="Time Loss (Minutes)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Time Loss (Minutes)"
                type="number"
                onChange={(e) => setTimeLoss(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CowStress">
            <FloatingLabel
              controlId="floatingInput"
              className="mb-3"  
            >
              <Select
                options={options}
                isMulti
                className="basic-multi-select"
                classNamePrefix="Animal Behaviour"
                placeholder="Animal Behaviour"
                onChange={(e) => setAnimalBehaviour(e)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="TeviotAnticoagulanat">
            <FloatingLabel
              controlId="floatingInput"
              label="Teviot Anticoagulanat (Number)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Teviot Anticoagulanat (Number)"
                type="number"
                onChange={(e) => setTeviotAnticoagulanat(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group> */}
          {/* <Container className="scannerC">
            <Row>
              <Col className="scanner">
                <Form.Group controlId="AX1ID">
                  <FloatingLabel controlId="floatingInput" label="AX1ID">
                    <Form.Control
                      placeholder="AX1ID"
                      type="text"
                      disabled
                      value={AX1ID}
                      onChange={(e) => setAX1ID(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={2} md={1}>
                <button
                  type="button"
                  disabled={AX1ID}
                  onClick={() => handleShow(1)}
                >
                  <BsUpcScan size={15} />
                </button>
              </Col>
            </Row>
          </Container> */}
          <LoaderButton
            block="true"
            type="submit"
            size="lg"
            variant="primary"
            isLoading={isLoading}
            className="format"
            disabled={!validateForm()}
          >
            Create
          </LoaderButton>
        </Form>
      </div>
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                await loadScannedEntry(result.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {scannerErrorMessage ? (
            <div className="justify-content-center alert alert-danger">
              Scanned Item already exists in the database!
            </div>
          ) : null}
          {scannerCustomErrorMessage ? (
            <div className="justify-content-center alert alert-danger">
              Please scan proper blood QR!
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={showError}
        onHide={handleErrorClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center alert alert-danger">
            Please upload 'CSV' file!
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../../../../lib/contextLib";
import { onError } from "../../../../../lib/errorLib";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";

export default function CowHome() {
  const [cowNames, setCowNameList] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  async function getCows() {
    const cowID = encodeURIComponent("FARM#SETUP$DRAKE#COWS");
    return await API.get("omeat", `/FARM/SETUP/${cowID}`);
  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const cows = await getCows();
        setCowNameList(cows);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderEntriesList() {
    return (
      <>
        <LinkContainer to="/farmadmin/cow/entries/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new entry</span>
          </ListGroup.Item>
        </LinkContainer>
        {cowNames.map((name, index) => (
          <LinkContainer
            key={index}
            to={`/farmadmin/cow/entries/${encodeURIComponent(name)}`}
          >
            <ListGroup.Item action key={index}>
              <span className="font-weight-bold">{name}</span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Omeat</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>
      </div>
    );
  }

  function renderEntries() {
    return (
      <div className="entries">
        <h2 className="pb-3 mt-1 mb-3 border-bottom">Cow Entries </h2>
        <ListGroup>{!isLoading && renderEntriesList()}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderEntries() : renderLander()}
    </div>
  );
}

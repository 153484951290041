import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { API, Storage } from "aws-amplify";
import { FloatingLabel, Accordion, Table, Modal, Row, Col, Container } from "react-bootstrap";
import { s3Upload } from "../../lib/awsLib";
import config from "../../config";
import { QrReader } from 'react-qr-reader';
import { BsUpcScan } from "react-icons/bs";
import "./CreateBarrelEntry.css";

export default function CreateBarrelEntry() {
    const file = useRef(null);
    const history = useHistory();
    const [currentfarmnumber, setcurrentfarmnumber] = useState(0);
    const [FarmTech, setFarmTech] = useState("");
    const [LineType, setLineType] = useState("");    
    const [QRCodeLinked, setQRCodeLinked] = useState("");
    const [AX1FarmEntry1, setAX1FarmEntry1] = useState(null);
    const [AX1FarmEntry2, setAX1FarmEntry2] = useState(null);
    const [AX1FarmEntry3, setAX1FarmEntry3] = useState(null);
    const [AX1FarmBatchID, setAX1FarmBatchID] = useState("");
    const [AX1FarmBatchID2, setAX1FarmBatchID2] = useState("");
    const [AX1FarmBatchID3, setAX1FarmBatchID3] = useState("");
    const [FarmBatchIDArray, setFarmBatchIDArray] = useState([]);
    const [FarmBatchIDCowNameArray, setFarmBatchIDCowNameArray] = useState([]);
    const [FarmBatchIDQRCodeArray, setFarmBatchIDQRCodeArray] = useState([]);
    const [FarmBatchIDCowKeyArray, setFarmBatchIDCowKeyArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [Notes, setNotes] = useState("");

    const [QRshow, setQRshow] = useState(false);
    const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
    const handleClose = () => setQRshow(false);
    const handleShow = (i) => { setcurrentfarmnumber(i); setscannerErrorMessage(false); setQRshow(true) };

    async function loadScannedEntry(QRCodeLinked) {
        console.log("QRCode:" + QRCodeLinked);
        if (QRCodeLinked) {
            console.log("scanned! now check if it exists ");
            const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
            if ((scannedID.length > 0) && (currentfarmnumber!==0)) {
                if (scannedID[0].GSI1SK) {
                    var attachment = scannedID[0].attachment;

                    if (attachment) {
                        scannedID[0].attachmentURL = await Storage.get(attachment);
                    }
                    
                    if (currentfarmnumber === 1) {
                        setAX1FarmBatchID(scannedID[0].sk);
                        setFarmBatchIDArray((FarmBatchIDArray) => [...FarmBatchIDArray, scannedID[0].sk]);
                        setFarmBatchIDCowNameArray((FarmBatchIDCowNameArray) => [...FarmBatchIDCowNameArray, scannedID[0].CowName]);
                        setFarmBatchIDCowKeyArray((FarmBatchIDCowKeyArray) => [...FarmBatchIDCowKeyArray, scannedID[0].GSI2PK]);
                        setFarmBatchIDQRCodeArray((FarmBatchIDQRCodeArray) => [...FarmBatchIDQRCodeArray, scannedID[0].QRCodeLinked])
                        
                        setAX1FarmEntry1(scannedID[0]);
                    } else {
                        if (currentfarmnumber === 2) {
                            setAX1FarmBatchID2(scannedID[0].sk);
                            setFarmBatchIDArray((FarmBatchIDArray) => [...FarmBatchIDArray, scannedID[0].sk]);
                            setFarmBatchIDCowNameArray((FarmBatchIDCowNameArray) => [...FarmBatchIDCowNameArray, scannedID[0].CowName]);
                            setFarmBatchIDCowKeyArray((FarmBatchIDCowKeyArray) => [...FarmBatchIDCowKeyArray, scannedID[0].GSI2PK]);
                            setFarmBatchIDQRCodeArray((FarmBatchIDQRCodeArray) => [...FarmBatchIDQRCodeArray, scannedID[0].QRCodeLinked])
                            setAX1FarmEntry2(scannedID[0]);
                        } else {
                            if (currentfarmnumber === 3) {
                                setAX1FarmBatchID3(scannedID[0].sk);
                                setFarmBatchIDArray((FarmBatchIDArray) => [...FarmBatchIDArray, scannedID[0].sk]);
                                setFarmBatchIDCowNameArray((FarmBatchIDCowNameArray) => [...FarmBatchIDCowNameArray, scannedID[0].CowName]);
                                setFarmBatchIDCowKeyArray((FarmBatchIDCowKeyArray) => [...FarmBatchIDCowKeyArray, scannedID[0].GSI2PK]);
                                setFarmBatchIDQRCodeArray((FarmBatchIDQRCodeArray) => [...FarmBatchIDQRCodeArray, scannedID[0].QRCodeLinked])
                                setAX1FarmEntry3(scannedID[0]);
                            }
                        }
                    }
                    handleClose();
                }
            } else {
                if(currentfarmnumber===0){
                    if(scannedID.length > 0 && scannedID[0].GSI1SK){
                        setscannerErrorMessage(true);

                    }else{
                        setQRCodeLinked(QRCodeLinked);
                        handleClose();
                    }
                }else{
                    setscannerErrorMessage(true);
                }
                
            }
        }


    }



    function validateForm() {
        return QRCodeLinked.length>0 && FarmTech.length>0 && AX1FarmEntry1!=null;
    }

    function formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    function handleFileChange(event) {
        file.current = event.target.files[0];
    }


    function updateEntry(Item) {
        return API.post("omeat", `/FARM/BARREL/entries`, {
            body: Item
        });
    }
    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    async function handleSubmit(event) {

        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
                1000000} MB.`
            );
            console.log("File size error");
            return;
        }
        setIsLoading(true);
        const attachment = file.current ? await s3Upload(file.current) : null;

        var FarmBatchIDs = FarmBatchIDArray.filter(onlyUnique);
        var FarmBatchIDCowNames = FarmBatchIDCowNameArray.filter(onlyUnique);
        var FarmBatchIDCowKeys = FarmBatchIDCowKeyArray.filter(onlyUnique);
        var FarmBatchIDQRs = FarmBatchIDQRCodeArray.filter(onlyUnique);

        await updateEntry({
            QRCodeLinked,
            AX1FarmBatchID,
            AX1FarmBatchID2,
            AX1FarmBatchID3,
            FarmTech,
            LineType,
            attachment,
            Notes,
            FarmBatchIDs,
            FarmBatchIDCowNames,
            FarmBatchIDCowKeys,
            FarmBatchIDQRs
        });


        setIsLoading(false);
        history.push("/farm/barrel");
    }


    function showFarm(AX1FarmEntry1) {

        return (<>
            <Accordion defaultActiveKey="0" className="scannerC mb-3">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{AX1FarmEntry1.Title}</Accordion.Header>
                    <Accordion.Body>
                        <Table className="tableDiv">

                            <tbody>
                                <tr>
                                    <td>CowName</td>
                                    <td className="fw-bold">{AX1FarmEntry1.CowName}</td>
                                </tr>
                                <tr>
                                    <td>Collection Date</td>
                                    <td className="fw-bold">{AX1FarmEntry1.CollectionDate}</td>
                                </tr>
                                <tr>
                                    <td>Collection Volume</td>
                                    <td className="fw-bold">{AX1FarmEntry1.CollectionVolume}</td>
                                </tr>
                                <tr>
                                    <td>StartTimeCow </td>
                                    <td className="fw-bold"> {AX1FarmEntry1.StartTimeCow}</td>
                                </tr>
                                <tr>
                                    <td>StartTimeApheresis</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.StartTimeApheresis}</td>
                                </tr>
                                <tr>
                                    <td>EndTimeApheresis</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.EndTimeApheresis}</td>
                                </tr>
                                <tr>
                                    <td>EndTime (Cow Release)</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.EndTimeCow}</td>
                                </tr>
                                <tr>
                                    <td>MachineErrors</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.MachineErrors}</td>
                                </tr>
                                <tr>
                                    <td>NewWashedLine</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.NewWashedLine}</td>
                                </tr>
                                <tr>
                                    <td>CatheterPosition</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.CatheterPosition}</td>
                                </tr>
                                <tr>
                                    <td>Notes</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.Notes}</td>
                                </tr>
                                <tr>
                                    <td>LeadTech</td>
                                    <td className="fw-bold"> {AX1FarmEntry1.LeadTech}</td>
                                </tr>
                                <tr>
                                    <td>Attachment</td>
                                    <td className="fw-bold">  {AX1FarmEntry1.attachment && (
                                        <p>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={AX1FarmEntry1.attachmentURL}
                                            >
                                                {formatFilename(AX1FarmEntry1.attachment)}
                                            </a>
                                        </p>
                                    )}
                                    </td>
                                </tr>

                            </tbody>
                        </Table>

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </>)
    }

    return (
        <>



            <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
                {1 && (
                    <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="Scanner">
                        <>
                        <button type="button" onClick={() => handleShow(0)}><BsUpcScan size={30} /></button>


                            <p>{QRCodeLinked}</p>
                        </>
                    </Form.Group>
                    <Form.Group controlId="QRCodeLinked">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Barrel QRCode"
                            className="mb-3"
                        >
                            <Form.Control
                                placeholder="Barrel QRCodeLinked"
                                disabled
                                type="text"
                                value={QRCodeLinked}
                            />
                        </FloatingLabel>
                    </Form.Group>

                        <Container className="scannerC">
                            <Row>
                                <Col className="scanner">
                                    <Form.Group controlId="AX1FarmBatchID">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Sample Bag 1 QR Code"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                placeholder="AX1FarmBatchID"
                                                type="text"
                                                disabled
                                                value={AX1FarmBatchID}
                                                onChange={(e) => setAX1FarmBatchID(e.target.value)}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col xs={2} md={1}>
                                    <button type="button" onClick={() => handleShow(1)}><BsUpcScan size={15} /></button>
                                </Col>

                            </Row>
                            {AX1FarmEntry1 && showFarm(AX1FarmEntry1)}
                        </Container>
                        <Container className="scannerC">
                            <Row>
                                <Col className="scanner">
                                    <Form.Group controlId="AX1FarmBatchID2">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Sample Bag 2 QR Code"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                placeholder="AX1FarmBatchID2"
                                                type="text"
                                                disabled
                                                value={AX1FarmBatchID2}
                                                onChange={(e) => setAX1FarmBatchID2(e.target.value)}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col xs={2} md={1}>
                                    <button type="button" onClick={() => handleShow(2)}><BsUpcScan size={15} /></button>
                                </Col>

                            </Row>
                            {AX1FarmEntry2 && showFarm(AX1FarmEntry2)}
                        </Container>
                        <Container className="scannerC">
                            <Row>
                                <Col className="scanner">
                                    <Form.Group controlId="AX1FarmBatchID3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Sample Bag 3 QR Code"
                                            className="mb-3"
                                        >
                                            <Form.Control
                                                placeholder="AX1FarmBatchID3"
                                                type="text"
                                                disabled
                                                value={AX1FarmBatchID3}
                                                onChange={(e) => setAX1FarmBatchID3(e.target.value)}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col xs={2} md={1}>
                                    <button type="button" onClick={() => handleShow(3)}><BsUpcScan size={15} /></button>
                                </Col>

                            </Row>
                            {AX1FarmEntry3 && showFarm(AX1FarmEntry3)}
                        </Container>
                        <Form.Group controlId="LineType">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Line"
                                className="mb-3"
                            >
                                <Form.Select className="mt-3" value={LineType} aria-label="Default select example" onChange={(e) => setLineType(e.target.value)}>
                                <option> </option>
                                <option value="New">New</option>
                                <option value="Washed">Washed</option>
                                </Form.Select>

                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group controlId="FarmTech">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Farm Tech"
                                className="mb-3"
                            >
                                <Form.Select className="mt-3" value={FarmTech} aria-label="Default select example" onChange={(e) => setFarmTech(e.target.value)}>
                                <option> </option>
                                <option value="Yazmin">Yazmin</option>
                                <option value="Anessa">Anessa</option>
                                <option value="Alex">Alex</option>
                                <option value="Lisa">Lisa</option>
                                </Form.Select>

                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="Notes">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Notes"
                                className="mb-3"
                            >
                                <Form.Control
                                    placeholder="Notes"
                                    type="text"
                                    value={Notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group controlId="file">
                            <Form.Label>Attachment</Form.Label>
                            <Form.Control onChange={handleFileChange} type="file" />
                        </Form.Group>
                        <LoaderButton
                            block="true"
                            type="submit"
                            size="lg"
                            variant="primary"
                            isLoading={isLoading}
                            className="format"
                            disabled={!validateForm()}
                        >
                            Create
                        </LoaderButton>
                    </Form>)}
                <Modal
                    show={QRshow}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <QrReader
                            constraints={{ facingMode: 'environment' }}
                            onResult={async (result, error) => {
                                if (!!result) {
                                    await loadScannedEntry(result.text);
                                    //handleClose();
                                }

                                if (!!error) {
                                    console.info(error);
                                }
                            }}
                            style={{ width: '100%' }}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        {
                            scannerErrorMessage ? (<div className="justify-content-center alert alert-danger">Scanned Item doesn't exist in the database</div>) : null
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}
import React from "react";
import "./Dashboard.css";
import NavLinkData from "../NavLinkData/NavLinkData";

export default function Dashboard() {
  const farmLinks = [
    { img: "farmer.png", link: "/farm", linkName: "Farm" },
    { img: "blood.png", link: "/blood", linkName: "Blood" },
    { img: "biopsy.png", link: "/biopsy", linkName: "Biopsy" },
    { img: "barrel.png", link: "/farm/barrel", linkName: "Barrel" },
    { img: "ship.png", link: "/farm/shipping", linkName: "Shipping" },
  ];

  const adminLinks = [
    { img: "farmer.png", link: "/admin", linkName: "Admin" },
  ];

  const labLinks = [
    { img: "microscope.png", link: "/lab", linkName: "Lab (AX1)" },
    { img: "recipe-book.png", link: "/lab/recipes", linkName: "Media Recipe (M)" },
    { img: "ax1m.png", link: "/lab/AX1M", linkName: "AX1M" },
    { img: "location.png", link: "/lab/delivery", linkName: "Delivery Point" },
  ];

  const adminSetUpLink = [
    { img: "setup.png", link: "/setup", linkName: "SetUp" },
  ];

  return (
    <div className="Dashboard container">
      <div className="NavButtons row">
      {(localStorage.getItem("userdepartment") === "admin" ||
          localStorage.getItem("isAdmin") === "true") &&
          adminLinks.map((data, index) => (
            <NavLinkData
              key={index}
              data={data}
              index={index}
              className="col-6"
            ></NavLinkData>
          ))}
        {(localStorage.getItem("userdepartment") === "farm" ||
          localStorage.getItem("isFarmUserandAdmin") === "true") &&
          farmLinks.map((data, index) => (
            <NavLinkData
              key={index}
              data={data}
              index={index}
              className="col-6"
            ></NavLinkData>
          ))}
        {(localStorage.getItem("userdepartment") === "lab" ||
          localStorage.getItem("isLabUserandAdmin") === "true") &&
          labLinks.map((data, index) => (
            <NavLinkData key={index} data={data} index={index}></NavLinkData>
          ))}
        {(localStorage.getItem("isLabAdmin") === "true" ||
          localStorage.getItem("isLabUserandAdmin") === "true" ||
          localStorage.getItem("isFarmAdmin") === "true" ||
          localStorage.getItem("isFarmUserandAdmin") === "true") &&
          adminSetUpLink.map((data, index) => (
            <NavLinkData key={index} data={data} index={index}></NavLinkData>
          ))}
      </div>
    </div>
  );
}

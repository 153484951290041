import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import { API } from "aws-amplify";
import "./LabHome.css";
import { LinkContainer } from "react-router-bootstrap";
import {
  Form,
  Button,
  Modal,
  FloatingLabel,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { BsUpcScan, BsSearch } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";

export default function Home() {
  const [entries, setEntries] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showonlyUserEntry, setshowonlyUserEntry] = useState(true);
  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const handleClose = () => setQRshow(false);
  const handleShow = () => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };

  const history = useHistory();

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      console.log("scannedID[0].GSI1SK" + scannedID[0]?.GSI1SK);
      if (scannedID.length > 0) {
        if (scannedID[0].GSI1SK) {
          console.log("scannedID[0].GSI1SK" + scannedID[0].GSI1SK);
          if (scannedID[0].GSI1SK.split("#")[0] === "FARM") {
            var farmBatchIDSample = "";
            var farmBatchIDBarrel = "";
            var farmBatchID;

            const farmBatchIDentries = await API.get(
              "omeat",
              `/allentries/${encodeURIComponent(scannedID[0].GSI1SK)}`
            );
            console.log(
              "farmBatchIDentries" + JSON.stringify(farmBatchIDentries, 2)
            );
            for (var i = 0; i < farmBatchIDentries.length; i++) {
              if (
                farmBatchIDentries[i].sk.includes("LAB#") &&
                (farmBatchIDentries[i].entryType === "sample" ||
                  farmBatchIDentries[i].entryType === "")
              ) {
                farmBatchIDSample = farmBatchIDentries[i];
              }
              if (
                farmBatchIDentries[i].sk.includes("LAB#") &&
                farmBatchIDentries[i].entryType === "barrel"
              ) {
                farmBatchIDBarrel = farmBatchIDentries[i];
                console.log(farmBatchIDBarrel);
              }
              console.log(
                "farmBatchID entryType" + farmBatchIDentries[i].entryType
              );
            }
            if (farmBatchIDSample) {
              farmBatchID = farmBatchIDSample;
            } else {
              if (scannedID[0].GSI1SK.includes("BARREL")) {
                farmBatchID = farmBatchIDentries[0];
              }
            }

            console.log("farmBatchID" + farmBatchID);
            if (farmBatchID && farmBatchID.sk) {
              let path = `/LAB/entries/${encodeURIComponent(farmBatchID.sk)}`;
              history.push(path);
            } else {
              setscannerErrorMessage(true);
            }
          } else {
            setscannerErrorMessage(true);
          }
        } else {
          setscannerErrorMessage(true);
        }
      } else {
        setscannerErrorMessage(true);
      }
    } else {
      setscannerErrorMessage(true);
    }
  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const entries = await loadUserEntries();
        setEntries(entries);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadUserEntries() {
    return API.get("omeat", "/LAB/entriesuser");
  }

  function loadAllEntries() {
    return API.get("omeat", "/LAB/entriesall");
  }
  const onSwitchEntry = async () => {
    try {
      if (!showonlyUserEntry) {
        var entries = await loadUserEntries();
      } else {
        entries = await loadAllEntries();
      }

      setEntries(entries);
      setshowonlyUserEntry(!showonlyUserEntry);
    } catch (e) {
      onError(e);
    }
    console.log("now calling API");
  };

  function renderEntriesList(entries) {
    return (
      <>
        <LinkContainer to="/lab/entries/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 bold font-weight-bold ">
              {" "}
              Create a new AX1BATCH entry
            </span>
          </ListGroup.Item>
        </LinkContainer>

        {entries.map(
          ({
            sk,
            FarmBatchIDQRs,
            LabTech,
            createdAt,
            FarmBatchIDCowNames,
            DONOTUSE,
          }) => (
            <LinkContainer
              key={sk}
              to={`/lab/entries/${encodeURIComponent(sk)}`}
            >
              <ListGroup.Item action className="listwrap">
                <span className="font-weight-bold">{sk}</span>
                <br />
                <span
                  className={
                    DONOTUSE
                      ? "list-group-item-danger text-muted"
                      : "text-muted"
                  }
                >
                  Lead Tech: {LabTech}, QRs : {FarmBatchIDQRs + ", "} Cows :{" "}
                  {FarmBatchIDCowNames}, Created:{" "}
                  {createdAt > 0
                    ? new Date(createdAt).toLocaleDateString() +
                      " " +
                      new Date(createdAt).toLocaleTimeString()
                    : ""}
                </span>
              </ListGroup.Item>
            </LinkContainer>
          )
        )}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Omeat</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>
      </div>
    );
  }

  function renderEntries() {
    return (
      <div className="entries">
        <button type="button" onClick={handleShow}>
          <BsUpcScan size={30} />
        </button>
        <Container className="scannerC">
          <Row>
            <Col className="scanner">
              <Form.Group controlId="AX1FarmBatchID">
                <FloatingLabel
                  controlId="floatingInput"
                  label="QR Code"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="QR Code"
                    type="text"
                    value={qrValue}
                    onKeyUp={() => setscannerErrorMessage(false)}
                    onChange={(e) => setQrValue(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col xs={2} md={1}>
              <button type="button" onClick={(e) => loadScannedEntry(qrValue)}>
                <BsSearch size={15} />
              </button>
            </Col>
            {scannerErrorMessage ? (
              <div className="alert alert-danger">
                Scanned Item doesn't exist in the database
              </div>
            ) : null}
          </Row>
        </Container>

        <h2 className="pb-3 mt-4 mb-3 border-bottom">Lab Entries </h2>

        <Form.Check
          type="switch"
          id="showonlyUserEntry"
          label="Show only my entries?"
          className="mb-3"
          defaultChecked={showonlyUserEntry}
          onChange={onSwitchEntry}
        />
        <ListGroup>{!isLoading && renderEntriesList(entries)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderEntries() : renderLander()}
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                await loadScannedEntry(result.text);
                //handleClose();
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {scannerErrorMessage ? (
            <div className="alert alert-danger">
              Scanned Item doesn't exist in the database
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./DeliveryHome.css";
import { LinkContainer } from "react-router-bootstrap";
import { FloatingLabel, Form, Button, Modal, Accordion } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { useHistory } from "react-router-dom";
import { BsUpcScan } from "react-icons/bs";

export default function DeliveryHome() {
  const [entries, setEntries] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeout, setIsTimeout] = useState(false);
  const [showonlyUserEntry, setshowonlyUserEntry] = useState(true);
  const [isScannerLoading, setIsScannerLoading] = useState(false);

  const [QRCodeLinked, setQRCodeLinked] = useState("");
  const [Notes, setNotes] = useState("");
  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    status: "error",
    message: "",
  });
  const [ConfirmShow, setConfirmShow] = useState(false);
  const handleConfirmClose = () => setConfirmShow(false);
  const handleConfirmShow = () => {
    setConfirmShow(true);
  };

  const handleClose = () => setQRshow(false);
  const handleShow = () => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };
  const history = useHistory();

  async function loadScannedEntry() {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      setIsScannerLoading(true);
      let d = new Date();
      const receivedDate = d.toISOString().split("T")[0];
      const receivedTime =
        (d.getHours() > 9 ? d.getHours() : "0" + d.getHours()) +
        ":" +
        (d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes());
      let Item = {
        receivedDate: receivedDate,
        receivedTime: receivedTime,
        recevingNotes: Notes,
      };
      const scannedID = await API.put(
        "omeat",
        `/SHIP/receipts/${QRCodeLinked}`,
        {
          body: Item,
        }
      );
      console.log(scannedID);
      if (scannedID.length > 0) {
        if (scannedID[0].pk) {
          let path = `/lab/delivery/${encodeURIComponent(scannedID[0].sk)}`;
          history.push(path);
        }
        setIsScannerLoading(false);
        setErrorMessage(scannedID);
        setscannerErrorMessage(true);
        setIsTimeout(true);
        setTimeout(() => {
          setIsTimeout(false);
        }, 5000);
        handleConfirmClose();
      } else {
        setIsScannerLoading(false);
        setErrorMessage(scannedID);
        setscannerErrorMessage(true);
        setIsTimeout(true);
        setTimeout(() => {
          setIsTimeout(false);
        }, 5000);
        handleConfirmClose();
      }
    }
  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const entries = await loadPendingEntries();
        setEntries(entries);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  async function loadPendingEntries() {
    return API.get("omeat", "/SHIP/entriesallLab?pending=Y");
  }

  function loadAllEntries() {
    return API.get("omeat", "/SHIP/entriesallLab?pending=N");
  }
  const filterResults = async (event) => {
    event.preventDefault();
    try {
      console.log("showonlyUserEntry:" + showonlyUserEntry);
      if (showonlyUserEntry) {
        var entries = await loadPendingEntries();
      } else {
        entries = await loadAllEntries();
      }

      setEntries(entries);
      //setshowonlyUserEntry(!showonlyUserEntry);
    } catch (e) {
      onError(e);
    }
    console.log("now calling API");
  };

  function renderEntriesList(entries) {
    return (
      <>
        {entries.map(({ sk, shipTime, shipDate, createdAt }) => (
          <LinkContainer
            key={sk}
            to={`/lab/delivery/${encodeURIComponent(sk)}`}
          >
            <ListGroup.Item action>
              <span className="font-weight-bold listwrap">{sk}</span>
              <br />
              <span className="text-muted">
                Shipping Date: {shipDate}, Shipping Time : {shipTime}, Created:{" "}
                {createdAt.length > 0
                  ? new Date(createdAt).toLocaleDateString() +
                    " " +
                    new Date(createdAt).toLocaleTimeString()
                  : ""}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Omeat</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>
      </div>
    );
  }

  function handleCheck() {
    setshowonlyUserEntry(!showonlyUserEntry);
  }

  const handleNotesModal = () => {
    handleConfirmShow();
  }

  function renderEntries() {
    return (
      <div className="entries">
        <div className="search-wrapper">
          <button type="button" onClick={handleShow}>
            <BsUpcScan size={30} />
          </button>
          <div className="search-form">
            <Form.Group controlId="QRCodeLinked">
              <FloatingLabel
                controlId="floatingInput"
                label="QR Code"
                className="mb-3 form-style"
              >
                <Form.Control
                  placeholder="QR Code Linked"
                  type="text"
                  onChange={(e) => setQRCodeLinked(e.target.value)}
                  value={QRCodeLinked}
                />
              </FloatingLabel>
            </Form.Group>
            {!isScannerLoading ? (<LoaderButton
              block="true"
              type="button"
              size="sm"
              variant="primary"
              className="format"
              onClick={handleNotesModal}
            >
              Search
            </LoaderButton> ):null}

            {isScannerLoading ? (
            <LoaderButton
              block="true"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isScannerLoading}
              className="format"
              disabled={true}
            >
              Loading...
            </LoaderButton>
          ) : null}
          </div>
        </div>
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Delivery Entries </h2>
        {scannerErrorMessage && isTimeout ? (
          <div
            className={
              errorMessage.status === "success"
                ? "alert alert-success"
                : "alert alert-danger"
            }
          >
            {errorMessage.message}
          </div>
        ) : null}
        <Accordion className="mb-3" defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filters </Accordion.Header>
            <Accordion.Body>
              <div className="filters mt-1 mb-5 border border-primary rounded">
                <div className="mt-3 mb-3 mx-3">
                  <Form className onSubmit={filterResults}>
                    <Form.Check
                      type="switch"
                      id="showonlyUserEntry"
                      label="Show only entries not received yet?"
                      className="mb-3"
                      defaultChecked={showonlyUserEntry}
                      onChange={handleCheck}
                    />
                    <LoaderButton
                      block="true"
                      type="submit"
                      size="sm"
                      variant="primary"
                      isLoading={isLoading}
                      className="format"
                    >
                      Apply
                    </LoaderButton>
                  </Form>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <ListGroup>{!isLoading && renderEntriesList(entries)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderEntries() : renderLander()}
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                setQRCodeLinked(result.text);
                handleClose();
                handleConfirmShow();
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {isScannerLoading ? (
            <LoaderButton
              block="true"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isScannerLoading}
              className="format"
              disabled={true}
            >
              Loading...
            </LoaderButton>
          ) : null}
          {scannerErrorMessage ? (
            <div
              className={
                errorMessage.status === "success"
                  ? "alert alert-success"
                  : "alert alert-danger"
              }
            >
              {errorMessage.message}
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
      <Modal
        show={ConfirmShow}
        onHide={handleConfirmClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Received Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={loadScannedEntry}>
            <Form.Group controlId="Notes">
              <FloatingLabel
                controlId="floatingInput"
                label="Notes"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Notes"
                  type="textarea"
                  value={Notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={loadScannedEntry}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
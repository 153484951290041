import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import DashboardCard from "../../../components/shared/DashboardCard";
import { API } from "aws-amplify";

const CollectionVolumeTotal = () => {
  // chart color
  const [totalShip, updateTotalVolume] = useState(0);

  async function loadDefUserEntries() {
    return API.get("omeat", "/ADMIN/collectionVolChart");
  }

  useEffect(() => {
    async function onLoad() {
      const result = await loadDefUserEntries();
      let monthData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let totalVolume = 0;

      result.map((data) => {
        data.CollectionVolume
          ? (totalVolume = totalVolume + parseInt(data.CollectionVolume))
          : (totalVolume = totalVolume + 0);
        monthData[new Date(data.CollectionDate).getMonth()] =
          monthData[new Date(data.CollectionDate).getMonth()] + 1;

        return monthData;
      });
      updateTotalVolume(totalVolume);
    }
    onLoad();
  }, []);

  return (
    <DashboardCard title="Total Collection Volume">
      <>
        <Typography variant="h4" fontWeight="700" mt="-20px">
           {totalShip} L
        </Typography>
      </>
    </DashboardCard>
  );
};

export default CollectionVolumeTotal;
import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { Auth, API, Storage } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import { FloatingLabel, Accordion, Table, Modal } from "react-bootstrap";
import { s3Upload } from "../../lib/awsLib";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import Select from "react-select";

import config from "../../config";

export default function Entries() {
  const file = useRef(null);
  const file1 = useRef(null);
  const file2 = useRef(null);
  const file3 = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [entry, setEntry] = useState(null);
  const [Title, setTitle] = useState("");
  const [cowNames, setCowNameList] = useState([]);
  const [CowName, setCowName] = useState("");
  const [CollectionDate, setCollectionDate] = useState("");
  const [StartTimeCow, setStartTimeCow] = useState("");
  const [StartTimeApheresis, setStartTimeApheresis] = useState("");
  const [EndTimeApheresis, setEndTimeApheresis] = useState("");
  const [EndTimeCow, setEndTimeCow] = useState("");
  const [MachineErrors, setMachineErrors] = useState("");
  // const [CowStress, setCowStress] = useState("");
  const [NewWashedLine, setNewWashedLine] = useState("");
  const [CollectionVolume, setCollectionVolume] = useState("");
  const [LeadTech, setLeadTech] = useState("");
  const [CatheterPosition, setCatheterPosition] = useState("");
  const [Machine, setMachine] = useState("");
  // const [Feeding, setFeeding] = useState(false);
  const [BloodWorkToday, setBloodWorkToday] = useState(false);
  // const [cowWashed, setcowWashed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Notes, setNotes] = useState("");
  const [QRCodeLinked, setQRCodeLinked] = useState("");
  const [QRshow, setQRshow] = useState(false);
  const [showError, setErrorShow] = useState(false);

  const [TimeLoss, setTimeLoss] = useState("");
  const [AnimalBehaviour, setAnimalBehaviour] = useState([]);
  const [TeviotAnticoagulanat, setTeviotAnticoagulanat] = useState("");

  const [InsideTemperatureStart, setInsideTemperatureStart] = useState("");
  const [OutsideTemperatureStart, setOutsideTemperatureStart] = useState("");
  const [CatheterPlacementTime, setCatheterPlacementTime] = useState("");
  const [InsideTemperatureEnd, setInsideTemperatureEnd] = useState("");
  const [OutsideTemperatureEnd, setOutsideTemperatureEnd] = useState("");
  const [CatheterExertionTime, setCatheterExertionTime] = useState("");
  const [bowl, setBowl] = useState();
  const [usageCount, setUsageCount] = useState(0);

  const handleClose = () => setQRshow(false);
  const handleShow = () => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };

  const handleErrorClose = () => setErrorShow(false);
  const handleErrorShow = () => {
    setErrorShow(true);
  };

  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const [curUserID, setcurUserID] = useState("");
  console.log(curUserID);

  const options = [
    { value: "thrashed", label: "thrashed" },
    { value: "calm", label: "calm" },
    { value: "caused_injury_to_self", label: "caused_injury_to_self" },
    { value: "extermely_reactive", label: "extermely_reactive" },
    { value: "moderately_reactive", label: "moderately_reactive" },
    { value: "slightly_reactive", label: "slightly_reactive" },
    { value: "collapsed", label: "collapsed" },
    { value: "relaxed", label: "relaxed" },
    { value: "chewing_cud", label: "chewing_cud" },
    { value: "did_not_want_put_head_through", label: "did_not_want_put_head_through" },
    { value: "non_reactive", label: "non_reactive" },
    { value: "being_naughty", label: "being_naughty" },
    { value: "laid_down_during_collection", label: "laid_down_during_collection" },
    { value: "vocalization", label: "vocalization" },

  ];

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0) {
        if (scannedID[0].GSI1SK) {
          setscannerErrorMessage(true);
        }
      } else {
        setQRCodeLinked(QRCodeLinked);
        handleClose();
      }
    }
  }
  async function getCows() {
    const cowID = encodeURIComponent("FARM#SETUP$DRAKE#COWS");
    return await API.get("omeat", `/FARM/SETUP/${cowID}`);
  }

  useEffect(() => {
    function loadEntry() {
      console.log("ID:" + id);
      //return API.get("omeat", `/FARM/entries/${id.replace(/#/g,"__")}`);
      return API.get("omeat", `/FARM/entries/${id}`);
    }

    async function onLoad() {
      try {
        const entry = await loadEntry();
        const CurrentUserID = await Auth.currentUserInfo();
        const cows = await getCows();
        setCowNameList(cows);
        console.log("CurrentUserID:" + CurrentUserID.id, entry.sk);
        setcurUserID(CurrentUserID.id);
        setTitle(entry.Title);
        setCowName(entry.CowName);
        setCollectionDate(entry.CollectionDate);
        setStartTimeCow(entry.StartTimeCow);
        setStartTimeApheresis(entry.StartTimeApheresis);
        setEndTimeApheresis(entry.EndTimeApheresis);
        setEndTimeCow(entry.EndTimeCow);
        setMachineErrors(entry.MachineErrors);
        // setCowStress(entry.CowStress);
        setNewWashedLine(entry.NewWashedLine);
        setCollectionVolume(entry.CollectionVolume);
        setLeadTech(entry.LeadTech);
        setCatheterPosition(entry.CatheterPosition);
        setMachine(entry.Machine);
        // setFeeding(entry.Feeding);
        setBloodWorkToday(entry.BloodWorkToday);
        // setcowWashed(entry.cowWashed);
        setNotes(entry.Notes);
        setQRCodeLinked(entry.QRCodeLinked);
        setTimeLoss(entry.TimeLoss);
        setAnimalBehaviour(entry.AnimalBehaviour);
        setTeviotAnticoagulanat(entry.TeviotAnticoagulanat);
        setBowl(entry.bowl);
        setUsageCount(entry.usageCount);
        setInsideTemperatureEnd(entry.InsideTemperatureEnd);
        setInsideTemperatureStart(entry.InsideTemperatureStart);
        setOutsideTemperatureEnd(entry.OutsideTemperatureEnd);
        setOutsideTemperatureStart(entry.OutsideTemperatureStart);
        setCatheterExertionTime(entry.CatheterExertionTime);
        setCatheterPlacementTime(entry.CatheterPlacementTime);
        var attachment = entry.attachment;
        var attachment2 = entry.attachment2;
        var attachment3 = entry.attachment3;
        var attachment4 = entry.attachment4;
        if(entry.bowl){
          let bowl = entry.bowl === 'Used Bowl' ? 'used' : 'new';
          onSelectBowl(bowl)
        }
        if (attachment) {
          entry.attachmentURL = await Storage.get(attachment);
        }

        if (attachment2) {
          entry.attachment2URL = await Storage.get(attachment2);
        }

        if (attachment3) {
          entry.attachment3URL = await Storage.get(attachment3);
        }

        if (attachment4) {
          entry.attachment4URL = await Storage.get(attachment4);
        }

        setEntry(entry);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return 1;
  }

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  function handleFileChange1(event) {
    file1.current = event.target.files[0];
  }

  function handleFileChange2(event) {
    if (
      event.target.files[0].name.split(".").pop() === "csv" ||
      event.target.files[0].name.split(".").pop() === "CSV"
    ) {
      file2.current = event.target.files[0];
    } else {
      handleErrorShow();
    }
  }

  function handleFileChange3(event) {
    if (
      event.target.files[0].name.split(".").pop() === "csv" ||
      event.target.files[0].name.split(".").pop() === "CSV"
    ) {
      file3.current = event.target.files[0];
    } else {
      handleErrorShow();
    }
  }

  // const onSwitchFeeding = () => {
  //   setFeeding(!Feeding);
  // };
  
  const onSwitchBloodWork = () => {
    setBloodWorkToday(!BloodWorkToday);
  };

  const onSelectBowl = (data) =>{
    data === "new" ? setBowl('New Bowl') : setBowl('Used Bowl');
    console.log(data);
  }

  function updateEntry(Item) {
    return API.put("omeat", `/FARM/entries/${encodeURIComponent(id)}`, {
      body: Item,
    });
  }

  function showChemResult(testName,results) {
    return (
      <>
        <Accordion className="scannerC mb-3">
          <Accordion.Item eventKey="1">
          <Accordion.Header>{testName} - Test Results</Accordion.Header>
            <Accordion.Body>
              <Table responsive className="tableDiv">
                <tbody>
                  <tr>
                    <th>TestName</th>
                    <th>TestUnits</th>
                    <th>TestValue</th>
                    <th>RefRangeHigh</th>
                    <th>RefRangeLow</th>
                    <th>TestDate</th>
                  </tr>
                  {results.map((result, index) => (
                    <tr key={index}>
                      <td>{result.TestName}</td>
                      <td>{result.TestUnits}</td>
                      <td>{result.TestValue}</td>
                      <td>{result.RefRangeHigh}</td>
                      <td>{result.RefRangeLow}</td>
                      <td>{result.TestDate}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      console.log("File size error");
      return;
    }

    if (file1.current && file1.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      console.log("File size error");
      return;
    }

    if (file2.current && file2.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    if (file3.current && file3.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);
    const attachment = file.current
      ? await s3Upload(file.current)
      : entry.attachment;
    const attachment2 = file1.current
      ? await s3Upload(file1.current)
      : entry.attachment2;

    const attachment3 = file2.current
      ? await s3Upload(file2.current)
      : entry.attachment3;
    const attachment4 = file3.current
      ? await s3Upload(file3.current)
      : entry.attachment4;
    await updateEntry({
      Title,
      QRCodeLinked,
      CowName,
      CollectionDate,
      StartTimeCow,
      StartTimeApheresis,
      EndTimeApheresis,
      EndTimeCow,
      MachineErrors,
      // CowStress,
      NewWashedLine,
      CollectionVolume,
      LeadTech,
      CatheterPosition,
      Machine,
      // Feeding,
      BloodWorkToday,
      // cowWashed,
      attachment,
      attachment2,
      attachment3,
      attachment4,
      bowl,
      usageCount,
      InsideTemperatureEnd,
      InsideTemperatureStart,
      OutsideTemperatureEnd,
      OutsideTemperatureStart,
      CatheterExertionTime,
      CatheterPlacementTime,
      Notes,
      TimeLoss,
      AnimalBehaviour,
      TeviotAnticoagulanat,
    });

    setIsLoading(false);
    history.push("/farm");
  }

  return (
    <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
      {entry && (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="Title">
            <FloatingLabel
              controlId="floatingInput"
              label="Title"
              className="mb-3"
            >
              <Form.Control
                placeholder="Title"
                type="text"
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="Scanner">
            <>
              <button type="button" onClick={handleShow}>
                <BsUpcScan size={30} />
              </button>

              <p>{QRCodeLinked}</p>
            </>
          </Form.Group>
          <Form.Group controlId="QRCodeLinked">
            <FloatingLabel
              controlId="floatingInput"
              label="QRCodeLinked"
              className="mb-3"
            >
              <Form.Control
                placeholder="QRCodeLinked"
                disabled
                type="text"
                value={QRCodeLinked}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CowName">
            <FloatingLabel
              controlId="floatingInput"
              label="Cow Name"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                value={CowName}
                aria-label="Default select example"
                onChange={(e) => setCowName(e.target.value)}
              >
                <option> </option>
                {cowNames.map((name, index) => (
                  <option key={index} value={name} defaultValue={name === CowName}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CollectionDate">
            <FloatingLabel
              controlId="floatingInput"
              label="Collection Date"
              className="mb-3"
            >
              <Form.Control
                type="date"
                name="duedate"
                value={CollectionDate}
                placeholder="Due date"
                onChange={(e) => setCollectionDate(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="StartTimeCow">
            <FloatingLabel
              controlId="floatingInput"
              label="Start time (Cows in place)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Start time (Cows in place)"
                value={StartTimeCow}
                type="time"
                onChange={(e) => setStartTimeCow(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="StartTimeApheresis">
            <FloatingLabel
              controlId="floatingInput"
              label="Start time running apheresis machine"
              className="mb-3"
            >
              <Form.Control
                placeholder="Start time running apheresis machine"
                type="time"
                value={StartTimeApheresis}
                onChange={(e) => setStartTimeApheresis(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="EndTimeApheresis">
            <FloatingLabel
              controlId="floatingInput"
              label="End time running apheresis machine"
              className="mb-3"
            >
              <Form.Control
                placeholder="End time running apheresis machine"
                type="time"
                value={EndTimeApheresis}
                onChange={(e) => setEndTimeApheresis(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="EndTimeCow">
            <FloatingLabel
              controlId="floatingInput"
              label="End time (Cow release)"
              className="mb-3"
            >
              <Form.Control
                placeholder="End time (Cow release)"
                type="time"
                value={EndTimeCow}
                onChange={(e) => setEndTimeCow(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          {/* <Form.Group controlId="CowStress">
            <FloatingLabel
              controlId="floatingInput"
              label="Cow Stress (Percentage)"
              className="mb-3"
            >
              <Form.Control
                placeholder="CowStress"
                type="number"
                value={CowStress}
                onChange={(e) => setCowStress(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group> */}
          <Form.Group controlId="MachineErrors">
            <FloatingLabel
              controlId="floatingInput"
              label="MachineErrors"
              className="mb-3"
            >
              <Form.Control
                placeholder="MachineErrors"
                type="text"
                value={MachineErrors}
                onChange={(e) => setMachineErrors(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="NewWashedLine">
            <FloatingLabel
              controlId="floatingInput"
              label="NewWashedLine"
              className="mb-3"
            >
              <Form.Control
                placeholder="NewWashedLine"
                type="text"
                value={NewWashedLine}
                onChange={(e) => setNewWashedLine(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CollectionVolume">
            <FloatingLabel
              controlId="floatingInput"
              label="Collection Vol (Litres)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Collection Vol (Litres)"
                type="number"
                step="0.01"
                value={CollectionVolume}
                onChange={(e) => setCollectionVolume(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="LeadTech">
            <FloatingLabel
              controlId="floatingInput"
              label="Lead Tech"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                value={LeadTech}
                aria-label="Default select example"
                onChange={(e) => setLeadTech(e.target.value)}
              >
                <option> </option>
                <option value="Yazmin">Yazmin</option>
                <option value="Marissa">Marissa </option>
                <option value="Alex">Alex</option>
                <option value="Lisa">Lisa</option>
                <option value="Sayumi">Sayumi</option>
                <option value="Karla">Karla</option>   
                <option value="Desiree">Desiree</option>              
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CatheterPosition">
            <FloatingLabel
              controlId="floatingInput"
              label="Catheter Position"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                value={CatheterPosition}
                aria-label="Default select example"
                onChange={(e) => setCatheterPosition(e.target.value)}
              >
                <option> </option>
                <option value="Right Jugular">Right Jugular</option>
                <option value="Left Jugular">Left Jugular</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="Machine">
            <FloatingLabel
              controlId="floatingInput"
              label="Machine"
              className="mb-3"
            >
              <Form.Select
                className="mt-3"
                value={Machine}
                aria-label="Default select example"
                onChange={(e) => setMachine(e.target.value)}
              >
                <option> </option>
                <option value="TT07G149">TT07G149</option>
                <option value="TT14B687">TT14B687</option>
                <option value="TT14B673">TT14B673</option>
                <option value="TT15k800">TT15k800</option>
                <option value="TT14B682">TT14B682</option>
                <option value="TT14B674">TT14B674</option>
                <option value="TT14B595">TT14B595</option>
                <option value="TT14K753">TT14K753</option>
                <option value="OMPROTO1">OMPROTO1</option>
                <option value="OMPROTO2">OMPROTO2</option>
                <option value="TT13F204">TT13F204</option>
                <option value="TT14G061">TT14G061</option>
                <option value="TT14K773">TT14K773</option>
                <option value="TT14B680">TT14B680</option>
                <option value="TT14B690">TT14B690</option>
                <option value="TT14B630">TT14B630</option>
                <option value="TT13F204">TT13F204</option>
                <option value="TT14C250">TT14C250</option>
                <option value="TT14C252">TT14C252</option>
                <option value="TT13M069">TT13M069</option>
                <option value="TT14C158">TT14C158</option>
                <option value="TT14B673">TT14B673</option>
                <option value="TT14B687">TT14B687</option>
                <option value="TT14B682">TT14B682</option>
                <option value="TT09D310">TT09D310</option>
                <option value="TT14L736">TT14L736</option>
                <option value="TT18F142">TT18F142</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          {/* <Form.Group className="mt-3" controlId="Feeding">
            <Form.Check
              type="switch"
              id="Feeding"
              label="Feeding?"
              checked={Feeding}
              className="mb-3"
              onChange={onSwitchFeeding}
            />
          </Form.Group> */}
          <Form.Group className="mt-3" controlId="BloodWorkToday">
            <Form.Check
              type="switch"
              id="BloodWorkToday"
              label="Blood Work Today?"
              className="mb-3"
              checked={BloodWorkToday}
              onChange={onSwitchBloodWork}
            />
          </Form.Group>
          {/* <Form.Group className="mt-3" controlId="cowWashed">
            <Form.Check
              type="switch"
              id="cowWashed"
              label="Cow Washed?"
              className="mb-3"
              checked={cowWashed}
              onChange={onSwitchcowWashed}
            />
          </Form.Group> */}
          <Form.Group className="mt-3" controlId="bowl">
            <Form.Check
              inline
              type="radio"
              name="bowl"
              id="bowl"
              label="New Bowl"
              checked={bowl === 'New Bowl'}
              className="mb-3"
              onChange={()=>onSelectBowl('new')}
            />
            <Form.Check
              inline
              type="radio"
              name="bowl"
              id="bowl1"
              label="Used Bowl"
              checked={bowl === 'Used Bowl'}
              className="mb-3"
              onChange={()=>onSelectBowl('used')}
            />
          </Form.Group>
          {bowl === 'Used Bowl' && <Form.Group controlId="StartTimeApheresis">
            <FloatingLabel
              controlId="floatingInput"
              label="Usage Count"
              className="mb-3"
            >
              <Form.Control
                placeholder="Usage Count"
                type="number"
                value={usageCount}
                onChange={(e) => setUsageCount(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>}

          <Form.Group controlId="InsideTemperatureStart">
            <FloatingLabel
              controlId="floatingInput"
              label="Inside Temperature (F) Start"
              className="mb-3"
            >
              <Form.Control
                placeholder="Inside Temperature (F) Start"
                type="text"
                value={InsideTemperatureStart}
                onChange={(e) => setInsideTemperatureStart(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="InsideTemperatureEnd">
            <FloatingLabel
              controlId="floatingInput"
              label="Inside Temperature (F) End"
              className="mb-3"
            >
              <Form.Control
                placeholder="Inside Temperature (F) End"
                type="text"
                value={InsideTemperatureEnd}
                onChange={(e) => setInsideTemperatureEnd(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="OutsideTemperatureStart">
            <FloatingLabel
              controlId="floatingInput"
              label="Outside Temperature (F) Start "
              className="mb-3"
            >
              <Form.Control
                placeholder="Outside Temperature (F) Start "
                type="text"
                value={OutsideTemperatureStart}
                onChange={(e) => setOutsideTemperatureStart(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="OutsideTemperature (F) End">
            <FloatingLabel
              controlId="floatingInput"
              label="Outside Temperature (F) End"
              className="mb-3"
            >
              <Form.Control
                placeholder="Outside Temperature End"
                type="text"
                value={OutsideTemperatureEnd}
                onChange={(e) => setOutsideTemperatureEnd(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CatheterPlacementTime">
            <FloatingLabel
              controlId="floatingInput"
              label="Catheter Placement Time"
              className="mb-3"
            >
              <Form.Control
                placeholder="Catheter Placement Time"
                type="time"
                value={CatheterPlacementTime}
                onChange={(e) => setCatheterPlacementTime(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CatheterExertionTime">
            <FloatingLabel
              controlId="floatingInput"
              label="Catheter Exertion Time"
              className="mb-3"
            >
              <Form.Control
                placeholder="Catheter Exertion Time"
                type="time"
                value={CatheterExertionTime}
                onChange={(e) => setCatheterExertionTime(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="Notes">
            <FloatingLabel
              controlId="floatingInput"
              label="Notes"
              className="mb-3"
            >
              <Form.Control
                placeholder="Notes"
                type="text"
                value={Notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="file">
            <Form.Label>Attachment</Form.Label>
            {entry.attachment && (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={entry.attachmentURL}
                >
                  {formatFilename(entry.attachment)}
                </a>
              </p>
            )}
            <Form.Control onChange={handleFileChange} type="file" />
          </Form.Group>
          <br />
          <Form.Group controlId="file">
            <Form.Label>Attachment2</Form.Label>
            {entry.attachment2 && (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={entry.attachment2URL}
                >
                  {formatFilename(entry.attachment2)}
                </a>
              </p>
            )}
            <Form.Control onChange={handleFileChange1} type="file" />
          </Form.Group>
          <br />
          <Form.Group controlId="file">
            <Form.Label>CBC Blood Test (Upload CSV)</Form.Label>
            {entry.attachment3 && (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={entry.attachment3URL}
                >
                  {formatFilename(entry.attachment3)}
                </a>
              </p>
            )}
            <Form.Control onChange={handleFileChange2} type="file" />
            <br />
            {entry.attachment3 &&
              entry.CBCResults &&
              showChemResult("CBC ",entry.CBCResults)}            

          </Form.Group>
          <br />
          <Form.Group controlId="file">
            <Form.Label>Chemistry Blood Test (Upload CSV)</Form.Label>
            {entry.attachment4 && (
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={entry.attachment4URL}
                >
                  {formatFilename(entry.attachment4)}
                </a>
              </p>
            )}
            <Form.Control onChange={handleFileChange3} type="file" />
            <br />
            {entry.attachment4 &&
              entry.chemResults &&
              showChemResult("Chemistry ",entry.chemResults)}
          </Form.Group>

          <br />
          <Form.Group controlId="TimeLoss">
            <FloatingLabel
              controlId="floatingInput"
              label="Time Loss (Minutes)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Time Loss (Minutes)"
                type="number"
                value={TimeLoss}
                onChange={(e) => setTimeLoss(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="CowStress">
            <FloatingLabel controlId="floatingInput" className="mb-3">
              <Select
                options={options}
                isMulti
                defaultValue={AnimalBehaviour}
                className="basic-multi-select"
                classNamePrefix="Animal Behaviour"
                placeholder="Animal Behaviour"
                onChange={(e) => setAnimalBehaviour(e)}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group controlId="TeviotAnticoagulanat">
            <FloatingLabel
              controlId="floatingInput"
              label="Teviot Anticoagulanat (Number)"
              className="mb-3"
            >
              <Form.Control
                placeholder="Teviot Anticoagulanat (Number)"
                type="number"
                step="0.01"
                value={TeviotAnticoagulanat}
                onChange={(e) => setTeviotAnticoagulanat(e.target.value)}
              />
            </FloatingLabel>
          </Form.Group>
            <LoaderButton
              block="true"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
              className="format"
              disabled={!validateForm()}
            >
              Update
            </LoaderButton>
        </Form>
      )}
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                await loadScannedEntry(result.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {scannerErrorMessage ? (
            <div className="justify-content-center alert alert-danger">
              Scanned Item already exists in the database!
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
      <Modal
        show={showError}
        onHide={handleErrorClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="justify-content-center alert alert-danger">
            Please upload 'CSV' file!
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

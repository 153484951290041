import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./containers/Home/Home";
import NotFound from "./containers/NotFound/NotFound";
import Login from "./components/Auth/Login/Login";
import Signup from "./components/Auth/Signup/Signup";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword";
import FarmHome from "./containers/Farm/FarmHome";
import LabHome from "./containers/Lab/LabHome";
import CreateFarmEntry from "./containers/Farm/CreateFarmEntry";
import CreateLabEntry from "./containers/Lab/CreateLabEntry";
import FarmEntries from "./containers/Farm/FarmEntries";
import LabEntries from "./containers/Lab/LabEntries";
import CreateRecipeEntry from "./containers/Recipe/CreateRecipeEntry";
import RecipeHome from "./containers/Recipe/RecipeHome";
import RecipeEntries from "./containers/Recipe/RecipeEntries";
import BarrelHome from "./containers/Barrel/BarrelHome";
import CreateBarrelEntry from "./containers/Barrel/CreateBarrelEntry";
import BarrelEntries from "./containers/Barrel/BarrelEntries";
import AdminDashboard from "./components/Admin/LabAdmin/LabAdmin";
import CowEntries from "./components/Admin/FarmAdmin/Cow/CowEntries/CowEntries";
import Dashboard from "./components/Dashboard/Dashboard";
import SetUp from "./components/SetUp/SetUp";
import CowHome from "./components/Admin/FarmAdmin/Cow/CowHome/CowHome";

import DeliveryHome from "./containers/Delivery/DeliveryHome";
import DeliveryEntries from "./containers/Delivery/DeliveryEntries";

import ShipHome from "./containers/Ship/ShipHome";
import CreateShipEntry from "./containers/Ship/CreateShipEntry";
import ShipEntries from "./containers/Ship/ShipEntries";

import AX1MHome from "./containers/AX1M/AX1MHome";
import CreateAX1MEntry from "./containers/AX1M/CreateAX1MEntry";
import AX1MEntries from "./containers/AX1M/AX1MEntries";
import BloodHome from "./containers/Blood/BloodHome";
import BloodEntries from "./containers/Blood/BloodEntries";
import CreateBloodEntry from "./containers/Blood/CreateBloodEntry";
import BiopsyHome from "./containers/Biopsy/BiopsyHome";
import BiopsyEntries from "./containers/Biopsy/BiopsyEntries";
import CreateBiopsyEntry from "./containers/Biopsy/CreateBiopsyEntry";
import AdminGraphDashboard from "./components/Admin/Admin/views/dashboard/Dashboard";

export default function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/login/reset" component={ResetPassword} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/setup" component={SetUp} />
      {localStorage.getItem("isFarmAdmin") === "true" ||
      localStorage.getItem("isFarmUserandAdmin") === "true" ? (
        <>
          <Switch>
            <AuthenticatedRoute
              exact
              path="/farmadmin"
              component={AdminDashboard}
            />
            <AuthenticatedRoute
              exact
              path="/farmadmin/cow"
              component={CowHome}
            />
            <AuthenticatedRoute
              exact
              path="/farmadmin/cow/entries/new"
              component={CowEntries}
            />
            <AuthenticatedRoute
              exact
              path="/farmadmin/cow/entries/:id"
              component={CowEntries}
            />
            <AuthenticatedRoute exact path="/farm" component={FarmHome} />
            <AuthenticatedRoute
              exact
              path="/farm/entries/new"
              component={CreateFarmEntry}
            />
            <AuthenticatedRoute
              exact
              path="/farm/entries/:id"
              component={FarmEntries}
            />
            <AuthenticatedRoute
              exact
              path="/blood"
              component={BloodHome}
            />
            <AuthenticatedRoute
              exact
              path="/blood/entries/new"
              component={CreateBloodEntry}
            />
            <AuthenticatedRoute
              exact
              path="/blood/entries/:id"
              component={BloodEntries}
            />            
            <AuthenticatedRoute
              exact
              path="/biopsy"
              component={BloodHome}
            />
            <AuthenticatedRoute
              exact
              path="/biopsy/entries/new"
              component={CreateBloodEntry}
            />
            <AuthenticatedRoute
              exact
              path="/biopsy/entries/:id"
              component={BloodEntries}
            />            
            <AuthenticatedRoute
              exact
              path="/farm/barrel"
              component={BarrelHome}
            />
            <AuthenticatedRoute
              exact
              path="/farm/barrel/new"
              component={CreateBarrelEntry}
            />
            <AuthenticatedRoute
              exact
              path="/farm/barrel/:id"
              component={BarrelEntries}
            />
          </Switch>
        </>
      ) : localStorage.getItem("userdepartment") === "lab" ? (
        <>
          <Switch>
            <AuthenticatedRoute exact path="/lab" component={LabHome} />
            <AuthenticatedRoute
              exact
              path="/lab/recipes"
              component={RecipeHome}
            />
            <AuthenticatedRoute
              exact
              path="/lab/recipes/new"
              component={CreateRecipeEntry}
            />
            <AuthenticatedRoute
              exact
              path="/lab/recipes/:id"
              component={RecipeEntries}
            />
            <AuthenticatedRoute
              exact
              path="/lab/entries/new"
              component={CreateLabEntry}
            />
            <AuthenticatedRoute
              exact
              path="/lab/entries/:id"
              component={LabEntries}
            />
            <AuthenticatedRoute
              exact
              path="/lab/delivery"
              component={DeliveryHome}
            />
            <AuthenticatedRoute
              exact
              path="/lab/delivery/:id"
              component={DeliveryEntries}
            />
            <AuthenticatedRoute exact path="/lab/AX1M" component={AX1MHome} />
            <AuthenticatedRoute
              exact
              path="/lab/AX1M/new"
              component={CreateAX1MEntry}
            />
            <AuthenticatedRoute
              exact
              path="/lab/AX1M/:id"
              component={AX1MEntries}
            />
          </Switch>
        </>
      ) : localStorage.getItem("userdepartment") === "admin" ? (
        <>
          <Switch>
            <AuthenticatedRoute
              exact
              path="/admin"
              component={AdminGraphDashboard}
            />
            <AuthenticatedRoute
              exact
              path="/admin/dashboard"
              component={AdminGraphDashboard}
            />
          </Switch>
        </>
      ) : (
        <>
          <Switch>
            <AuthenticatedRoute exact path="/farm" component={FarmHome} />
            <AuthenticatedRoute
              exact
              path="/farm/entries/new"
              component={CreateFarmEntry}
            />
            <AuthenticatedRoute
              exact
              path="/farm/entries/:id"
              component={FarmEntries}
            />
            <AuthenticatedRoute
              exact
              path="/blood"
              component={BloodHome}
            />
            <AuthenticatedRoute
              exact
              path="/blood/entries/new"
              component={CreateBloodEntry}
            />
            <AuthenticatedRoute
              exact
              path="/blood/entries/:id"
              component={BloodEntries}
            />            
            <AuthenticatedRoute
              exact
              path="/biopsy"
              component={BiopsyHome}
            />
            <AuthenticatedRoute
              exact
              path="/biopsy/entries/new"
              component={CreateBiopsyEntry}
            />
            <AuthenticatedRoute
              exact
              path="/biopsy/entries/:id"
              component={BiopsyEntries}
            />            
            <AuthenticatedRoute
              exact
              path="/farm/barrel"
              component={BarrelHome}
            />
            <AuthenticatedRoute
              exact
              path="/farm/barrel/new"
              component={CreateBarrelEntry}
            />
            <AuthenticatedRoute
              exact
              path="/farm/barrel/:id"
              component={BarrelEntries}
            />
            <AuthenticatedRoute
              exact
              path="/farm/shipping"
              component={ShipHome}
            />
            <AuthenticatedRoute
              exact
              path="/farm/shipping/new"
              component={CreateShipEntry}
            />
            <AuthenticatedRoute
              exact
              path="/farm/shipping/:id"
              component={ShipEntries}
            />
          </Switch>
        </>
      )}

      <Route path="*" component={NotFound} />
    </Switch>
  );
}

import React from "react";
import { useAppContext } from "../../lib/contextLib";
import "./Home.css";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function Home() {
  // const [entries, setEntries] = useState([]);
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  // useEffect(() => {
  //   async function onLoad() {
  //     if (!isAuthenticated) {
  //       return;
  //     }

  //     try {
  //       const entries = await loadUserEntries();
  //       setEntries(entries);
  //     } catch (e) {
  //       onError(e);
  //     }

  //     setIsLoading(false);
  //   }

  //   onLoad();
  // }, [isAuthenticated]);

  // function loadUserEntries() {
  //   return API.get("omeat", "/FARM/entriesuser");
  // }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Omeat</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? history.push("/dashboard") : renderLander()}
    </div>
  );
}

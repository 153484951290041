import React, { useState, useEffect, useCallback } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./AX1MHome.css";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Button, Modal, Accordion } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import { useHistory } from "react-router-dom";

export default function AX1MHome() {
  const [entries, setEntries] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showonlyUserEntry, setshowonlyUserEntry] = useState(true);
  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const handleClose = () => setQRshow(false);
  const handleShow = () => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };

  const history = useHistory();

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      console.log(scannedID);
      if (scannedID.length > 0) {
        if (
          scannedID[0].pk &&
          (scannedID[0].pk.includes("LAB") || scannedID[0].pk.includes("FARM"))
        ) {
          let path = `/lab/AX1M/${encodeURIComponent(scannedID[0].sk)}`;
          history.push(path);
        }
      } else {
        setscannerErrorMessage(true);
      }
    }
  }
  
  const getEntriesData = useCallback(async (entries) => {
    for (let i = 0; i < entries.length; i++) {
      let qrData = [];
      const { QRcode } = await loadBasalEntry(entries[i].BASALRECIPEID);
      entries[i]["RecipeId"] = QRcode;
      for (let j = 0; j < entries[i].AX1BATCHIDs.length; j++) {
        const farmData = await API.get(
          "omeat",
          `/FARM/entries/${encodeURIComponent(entries[i].AX1BATCHIDs[j])}`
        );
        qrData.push(farmData.BarrelQRCodeLinked);
      }
      entries[i] = { ...entries[i], qrData };
    }

    setEntries(entries);
  }, []);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const entries = await loadDefUserEntries();
        await getEntriesData(entries);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated, getEntriesData]);

  async function loadDefUserEntries() {
    return API.get("omeat", "/LAB/AX1M/entriesuser");
  }

  async function loadUserEntries() {
    return API.get("omeat", "/LAB/AX1M/entriesuser");
  }

  function loadAllEntries() {
    return API.get("omeat", "/LAB/AX1M/entriesall");
  }
  const filterResults = async (event) => {
    event.preventDefault();
    try {
      console.log("showonlyUserEntry:" + showonlyUserEntry);
      if (showonlyUserEntry) {
        var entries = await loadUserEntries();
      } else {
        entries = await loadAllEntries();
      }

      await getEntriesData(entries);
    } catch (e) {
      onError(e);
    }
    console.log("now calling API");
  };

  function loadBasalEntry(BasalId) {
    return API.get(
      "omeat",
      `/LAB/RECIPE/entries/${encodeURIComponent(BasalId)}`
    );
  }


  function renderEntriesList(entries) {
    return (
      <>
        <LinkContainer to="/lab/AX1M/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new AX1M</span>
          </ListGroup.Item>
        </LinkContainer>
        {entries.map(
          ({ sk, qrData, RecipeId, AX1MQRCodeLinked, createdAt }) => (
            <LinkContainer key={sk} to={`/lab/AX1M/${encodeURIComponent(sk)}`}>
              <ListGroup.Item action>
                <span className="font-weight-bold listwrap">{sk}</span>
                <br />
                <span className="text-muted">
                  AX1MQRCodeLinked: {AX1MQRCodeLinked}, Media QR: {RecipeId},
                  QRs : {qrData + ", "}
                  Created:{" "}
                  {createdAt > 0
                    ? new Date(createdAt).toLocaleDateString() +
                      " " +
                      new Date(createdAt).toLocaleTimeString()
                    : ""}
                </span>
              </ListGroup.Item>
            </LinkContainer>
          )
        )}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Omeat</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>
      </div>
    );
  }

  function handleCheck() {
    setshowonlyUserEntry(!showonlyUserEntry);
  }

  function renderEntries() {
    return (
      <div className="entries">
        <button type="button" onClick={handleShow}>
          <BsUpcScan size={30} />
        </button>
        <h2 className="pb-3 mt-4 mb-3 border-bottom">AX1M Entries </h2>
        <Accordion className="mb-3" defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filters </Accordion.Header>
            <Accordion.Body>
              <div className="filters mt-1 mb-5 border border-primary rounded">
                <div className="mt-3 mb-3 mx-3">
                  <Form className onSubmit={filterResults}>
                    <Form.Check
                      type="switch"
                      id="showonlyUserEntry"
                      label="Show only my entries?"
                      className="mb-3"
                      defaultChecked={showonlyUserEntry}
                      onChange={handleCheck}
                    />
                    <LoaderButton
                      block="true"
                      type="submit"
                      size="sm"
                      variant="primary"
                      isLoading={isLoading}
                      className="format"
                    >
                      Apply
                    </LoaderButton>
                  </Form>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <ListGroup>{!isLoading && renderEntriesList(entries)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderEntries() : renderLander()}
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                await loadScannedEntry(result.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {scannerErrorMessage ? (
            <div className="alert alert-danger">
              Scanned Item doesn't exist in the database
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
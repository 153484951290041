import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../LoaderButton";
import { useAppContext } from "../../../lib/contextLib";
import { useFormFields } from "../../../lib/hooksLib";
import { onError } from "../../../lib/errorLib";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Login.css";

export default function Login() {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      var userInfo = await Auth.currentUserInfo();
      var department = userInfo.attributes["custom:department"];
      const departs = department.split(",");
      localStorage.setItem("userdepartment", department);
      if (departs[0] === "labAdmin" && departs[1] === undefined) {
        localStorage.setItem("isLabAdmin", "true");
      } else if (departs[0] === "farmAdmin" && departs[1] === undefined) {
        localStorage.setItem("isFarmAdmin", "true");
      } else if (departs[0] === "farm" && departs[1] === "farmAdmin") {
        localStorage.setItem("isFarmUserandAdmin", "true");
      } else if (departs[0] === "lab" && departs[1] === "labAdmin") {
        localStorage.setItem("isLabUserandAdmin", "true");
      } else if (departs[0] === "admin" && departs[1] === "admin") {
        localStorage.setItem("isAdmin", "true");
      }

      userHasAuthenticated(true);
      history.push("/dashboard");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block="true"
          size="lg"
          type="submit"
          isLoading={isLoading}
          className="format"
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </Form>
    </div>
  );
}

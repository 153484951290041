import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import { FloatingLabel, Modal, Table, Container } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import "./CreateShipEntry.css";
import Trash from "../../images/trash.png";

export default function CreateShipEntry() {
  let d = new Date();
  const receivedDate = d.toISOString().split("T")[0];
  const receivedTime =
    (d.getHours() > 9 ? d.getHours() : "0" + d.getHours()) +
    ":" +
    (d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes());
  const history = useHistory();
  const [shipTime, setShipTime] = useState(receivedTime);
  const [shipDate, setShipDate] = useState(receivedDate);
  const [Notes, setNotes] = useState('');
  const [ScannedQRArray, setScannedQRArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScannerLoading, setIsScannerLoading] = useState(false);

  const [QRshow, setQRshow] = useState(false);
  const [ConfirmShow, setConfirmShow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const handleConfirmClose = () => setConfirmShow(false);
  const handleClose = () => setQRshow(false);
  const handleShow = (i) => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };
  const handleConfirmShow = (i) => {
    setConfirmShow(true);
  };

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      setIsScannerLoading(true);
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0) {
        console.log('response')
        let newObj = {
          batch: scannedID[0].sk,
          received: "N",
          qr: QRCodeLinked,
        };
        setScannedQRArray([...ScannedQRArray, newObj]);
        handleClose();
        setIsScannerLoading(false);
      } else {
        console.log('error')
        setscannerErrorMessage(true);
        setIsScannerLoading(false);
      }
    }
  }

  function validateForm() {
    return (
      ScannedQRArray.length > 0 && shipTime.length > 0 && shipDate.length > 0
    );
  }

  function updateEntry(Item) {
    return API.post("omeat", `/SHIP/entries`, {
      body: Item,
    });
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const removeQRfromArray = (qr, i) => {
    setScannedQRArray(
      ScannedQRArray.filter((qrValue) => {
        return qrValue !== qr;
      })
    );
  };

  async function handleSubmit(event) {
    event.preventDefault();

    handleConfirmShow();
  }

  async function proceedCancel() {
    handleConfirmClose();
  }

  async function proceedSubmit() {
    handleConfirmClose();
    setIsLoading(true);

    var FarmBatchIDs = ScannedQRArray.filter(onlyUnique);

    await updateEntry({
      shipDate,
      shipTime,
      FarmBatchIDs,
      Notes
    });

    setIsLoading(false);
    history.push("/farm/shipping");
  }

  return (
    <>
      <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Create Entries </h2>
        {1 && (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="Shipping Date">
              <FloatingLabel
                controlId="floatingInput"
                label="Shipping Date"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Shipping Date"
                  type="date"
                  value={shipDate}
                  onChange={(e) => setShipDate(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Shipping Time">
              <FloatingLabel
                controlId="floatingInput"
                label="Shipping Time"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Shipping Time"
                  type="time"
                  value={shipTime}
                  onChange={(e) => setShipTime(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Container className="scannerC">
              {ScannedQRArray.length > 0 && (
                <>
                  {" "}
                  <Table className="tableDiv">
                    <tbody>
                      <tr>
                        <td className="fw-bold">QR Code</td>
                        <td className="fw-bold">Trash</td>
                      </tr>
                      {ScannedQRArray.map((qr, index) => {
                        return (
                          <tr key={index}>
                            <td>{qr.qr}</td>
                            <td className="fw-bold">
                              <button
                                className="trashCls"
                                type="button"
                                onClick={() => removeQRfromArray(qr, index)}
                              >
                                <img
                                  src={Trash}
                                  width="20"
                                  height="20"
                                  className=""
                                  alt=" "
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </Container>
            <Form.Group controlId="Notes">
              <FloatingLabel
                controlId="floatingInput"
                label="Notes"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Notes"
                  type="textarea"
                  value={Notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Scanner">
              <div className="floatingQRContainer">
                <div className="floatingQR">
                  <button
                    type="button"
                    className="trashCls"
                    onClick={() => handleShow(0)}
                  >
                    <BsUpcScan size={25} />
                  </button>
                </div>
              </div>
            </Form.Group>
            <LoaderButton
              block="true"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
              className="format"
              disabled={!validateForm()}
            >
              Create
            </LoaderButton>
          </Form>
        )}
        <Modal
          show={QRshow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Scanner
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={async (result, error) => {
                if (!!result) {
                  await loadScannedEntry(result.text);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              style={{ width: "100%" }}
            />
          </Modal.Body>
          <Modal.Footer>
            {isScannerLoading ? (
              <LoaderButton
                block="true"
                type="submit"
                size="lg"
                variant="primary"
                isLoading={isScannerLoading}
                className="format"
                disabled={true}
              >
                Loading...
              </LoaderButton>
            ) : null}
            {scannerErrorMessage ? (
              <div className="justify-content-center alert alert-danger">
                Scanned Item doesn't exist in the database
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
        <Modal
          show={ConfirmShow}
          onHide={handleConfirmClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Once submitted, it cannot be edited. To proceed, please press 'Yes' else
              press 'No' to cancel
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={proceedSubmit}>
              Yes
            </button>
            <button className="btn btn-light" onClick={proceedCancel}>
              No
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import DashboardCard from "../../../components/shared/DashboardCard";
import Chart from "react-apexcharts";
import { API } from "aws-amplify";
import { Typography } from "@mui/material";

const CollectionVolumeByCow = () => {
  // select
  const [month, setMonth] = React.useState("0");
  const [cowName, setCowName] = React.useState("s");
  const currentYear = new Date().getFullYear();
  const [totalCollection, setTotalCollection] = React.useState(0);
  const [year, setYear] = React.useState(currentYear);

  let [collectionData, setCollectionData] = useState([]);
  let [cowData, setCowData] = useState([]);

  let [collectionVolumeEntryData, updatecollectionVolumeEntryData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  let [collectionVolumeTotalData, updatecollectionVolumeTotalData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const handleChange = (event) => {
    let month = event.target.value;
    setMonth(month);
    if (month === "1" || month === 1) {
      graphCollectionData(currentYear);
      graphCollectionVolumeData(currentYear);
      setYear(currentYear);
    } else if (month === "2" || month === 2) {
      graphCollectionData(currentYear - 1);
      graphCollectionVolumeData(currentYear - 1);
      setYear(currentYear - 1);
    } else if (month === "3" || month === 3) {
      graphCollectionData(currentYear - 2);
      graphCollectionVolumeData(currentYear - 2);
      setYear(currentYear - 2);
    }
  };

  const handleCowNameChange = (event) => {
    console.log(year, month);
    let name = event.target.value;
    setCowName(name);
    graphCollectionByNameData(name);
    graphCollectionVolumeByNameData(name);
  };

  const optionscolumnchart = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["rgba(129,180,121,0.8)", "#545454"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Collection Volume",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 0.5,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Collection Volume Entry",
      },
      min: 0,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };
  const seriescolumnchart = [
    {
      name: "Collection Entry",
      data: collectionVolumeEntryData,
    },
    {
      name: "Total Volume",
      data: collectionVolumeTotalData,
    },
  ];

  async function loadFarmUserEntries() {
    return API.get("omeat", "/ADMIN/collectionVolChart");
  }

  const graphCollectionData = (year) => {
    let collectionVolData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    collectionData.map((data) => {
      if (year === "0" && cowName === "s") {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else if (
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        cowName === "s"
      ) {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else if (year === "0" && cowName !== "s" &&
      data.CowName === cowName) {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else if (
        year !== "0" &&
        cowName !== "s" &&
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        data.CowName === cowName
      ) {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 0;
      }
      return collectionVolData;
    });
    updatecollectionVolumeEntryData(collectionVolData);
  };

  const graphCollectionVolumeData = (year) => {
    let collectionVolumeData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    collectionData.map((data) => {
      if (year === "0" && cowName === "s") {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else if (
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        cowName === "s"
      ) {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else if (year === "0" && cowName !== "s" &&
      data.CowName === cowName) {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else if (
        year !== "0" &&
        cowName !== "s" &&
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        data.CowName === cowName
      ) {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          collectionVolumeData[new Date(data.CollectionDate).getMonth()] + 0;
      }

      // if (parseInt(new Date(data.CollectionDate).getFullYear()) === year) {
      //   collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
      //     parseInt(data.CollectionVolume)
      //       ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
      //         parseInt(data.CollectionVolume)
      //       : collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
      //         0;
      //   setTotalCollection(
      //     collectionVolumeData.reduce((partialSum, a) => partialSum + a, 0)
      //   );
      // } else {
      //   collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
      //     collectionVolumeData[new Date(data.CollectionDate).getMonth()] + 0;
      //   setTotalCollection(
      //     collectionVolumeData.reduce((partialSum, a) => partialSum + a, 0)
      //   );
      // }
      setTotalCollection(
        collectionVolumeData.reduce((partialSum, a) => partialSum + a, 0)
      );
      return collectionVolumeData;
    });
    updatecollectionVolumeTotalData(collectionVolumeData);
  };

  const graphCollectionByNameData = (cowName) => {
    let collectionVolData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    collectionData.map((data) => {
      // data.CowName === cowName
      //   ? (collectionVolData[new Date(data.CollectionDate).getMonth()] =
      //       collectionVolData[new Date(data.CollectionDate).getMonth()] + 1)
      //   : (collectionVolData[new Date(data.CollectionDate).getMonth()] =
      //       collectionVolData[new Date(data.CollectionDate).getMonth()] + 0);
      if (year === "0" && cowName === "s") {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else if (
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        cowName === "s"
      ) {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else if (year === "0" && cowName !== "s" &&
      data.CowName === cowName) {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else if (
        year !== "0" &&
        cowName !== "s" &&
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        data.CowName === cowName
      ) {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;
      } else {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 0;
      }
      return collectionVolData;
    });
    updatecollectionVolumeEntryData(collectionVolData);
  };

  const graphCollectionVolumeByNameData = (cowName) => {
    let collectionVolumeData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    collectionData.map((data) => {
      if (year === "0" && cowName === "s") {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else if (
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        cowName === "s"
      ) {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else if (year === "0" && cowName !== "s" &&
      data.CowName === cowName) {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else if (
        year !== "0" &&
        cowName !== "s" &&
        parseInt(new Date(data.CollectionDate).getFullYear()) === year &&
        data.CowName === cowName
      ) {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : (collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
                collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
                0);
      } else {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          collectionVolumeData[new Date(data.CollectionDate).getMonth()] + 0;
      }
      setTotalCollection(
        collectionVolumeData.reduce((partialSum, a) => partialSum + a, 0)
      );
      // if (data.CowName === cowName) {
      //   collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
      //     parseInt(data.CollectionVolume)
      //       ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
      //         parseInt(data.CollectionVolume)
      //       : collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
      //         0;
      //   setTotalCollection(
      //     collectionVolumeData.reduce((partialSum, a) => partialSum + a, 0)
      //   );
      // } else {
      //   collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
      //     collectionVolumeData[new Date(data.CollectionDate).getMonth()] + 0;
      // }
      return collectionVolumeData;
    });
    updatecollectionVolumeTotalData(collectionVolumeData);
  };

  useEffect(() => {
    async function onLoad() {
      setYear("0");
      const result = await loadFarmUserEntries();
      setCollectionData([...result]);

      let cowNameData = [];
      result.map((data) => {
        cowNameData.push(data.CowName);
        return data;
      });

      setCowData((c) => cowNameData.filter(onlyUnique));

      // const result1 = await loadBarrelUserEntries();
      let collectionVolData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let collectionVolumeData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      result.map((data) => {
        collectionVolData[new Date(data.CollectionDate).getMonth()] =
          collectionVolData[new Date(data.CollectionDate).getMonth()] + 1;

        return collectionVolData;
      });

      result.map((data) => {
        collectionVolumeData[new Date(data.CollectionDate).getMonth()] =
          parseInt(data.CollectionVolume)
            ? collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              parseInt(data.CollectionVolume)
            : collectionVolumeData[new Date(data.CollectionDate).getMonth()] +
              0;
        setTotalCollection(
          collectionVolumeData.reduce((partialSum, a) => partialSum + a, 0)
        );
        return collectionVolumeData;
      });
      updatecollectionVolumeEntryData(collectionVolData);
      updatecollectionVolumeTotalData(collectionVolumeData);
    }
    onLoad();
  }, []);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  return (
    <DashboardCard
      title="Collection Volume per Cow Overview"
      action={
        <>
          <Select
            labelId="month-dd"
            id="month-dd"
            value={month}
            size="small"
            onChange={handleChange}
          >
            <MenuItem value={"0"}>--Select Year--</MenuItem>
            <MenuItem value={1}>{new Date().getFullYear()}</MenuItem>
            <MenuItem value={2}>{new Date().getFullYear() - 1}</MenuItem>
            <MenuItem value={3}>{new Date().getFullYear() - 2}</MenuItem>
          </Select>
          <Select value={cowName} size="small" onChange={handleCowNameChange}>
            <MenuItem value={"s"}>--Select Cow--</MenuItem>
            {cowData &&
              cowData.map((data, index) => {
                return (
                  <MenuItem key={index} value={data}>
                    {data}
                  </MenuItem>
                );
              })}
          </Select>
        </>
      }
    >
      <Typography variant="h6" fontWeight="700" mt="-20px" mb="20px">
        Total Collection Volume for {" "}
        {year === "0" ? "All Years" : year} for {" "}
        {cowName === "s" ? "All Cows" : cowName} : {totalCollection}
      </Typography>
      <Chart
        options={optionscolumnchart}
        series={seriescolumnchart}
        type="line"
        height="370px"
      />
    </DashboardCard>
  );
};

export default CollectionVolumeByCow;
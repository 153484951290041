import React, { useState } from "react";
import { API } from "aws-amplify";
import "./CreateRecipeEntry.css";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { FloatingLabel, Modal } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import "./CreateRecipeEntry.css";

export default function CreateRecipeEntry() {
  const history = useHistory();

  let d = new Date();
  const receivedDate = d.toISOString().split("T")[0];
  const receivedTime =
    (d.getHours() > 9 ? d.getHours() : "0" + d.getHours()) +
    ":" +
    (d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes());

  const [createdTime, setCreatedTime] = useState(receivedTime);
  const [createdDate, setCreatedDate] = useState(receivedDate);
  const [volume, setVolume] = useState(1);
  const [qrLinked, setQrLinked] = useState("");
  const [Notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isScannerLoading, setIsScannerLoading] = useState(false);

  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const handleClose = () => setQRshow(false);
  const handleShow = (i) => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      setIsScannerLoading(true);
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0) {
        if (scannedID[0].GSI1SK) {
          setscannerErrorMessage(true);
        }
        setIsScannerLoading(false);
      } else {
        setQrLinked(QRCodeLinked);
        setIsScannerLoading(false);
        handleClose();
      }
    }
  }

  function validateForm() {
    return (
      qrLinked.length > 0 &&
      createdTime.length > 0 &&
      createdDate.length > 0 &&
      volume > 0
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let startRecipe = {
      QRcode: qrLinked,
      createdDate: createdDate,
      createdTime: createdTime,
      volume: volume,
      Notes: Notes,
      completedSteps: [],
    };

    const newRecipe = await API.post("omeat", "/LAB/RECIPE/entries", {
      body: startRecipe,
    });

    setIsLoading(false);
    history.push(`/lab/recipes/${encodeURIComponent(newRecipe)}`);
  }

  return (
    <>
      <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
        {1 && (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="QR Linked">
              <FloatingLabel
                controlId="floatingInput"
                label="QR Linked"
                className="mb-3"
              >
                <Form.Control
                  placeholder="QR Linked"
                  type="text"
                  value={qrLinked}
                  disabled
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Created Date">
              <FloatingLabel
                controlId="floatingInput"
                label="Created Date"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Created Date"
                  type="date"
                  value={createdDate}
                  onChange={(e) => setCreatedDate(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Created Time">
              <FloatingLabel
                controlId="floatingInput"
                label="Created Time"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Created Time"
                  type="time"
                  value={createdTime}
                  onChange={(e) => setCreatedTime(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Volume">
              <FloatingLabel
                controlId="floatingInput"
                label="Volume"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Volume"
                  type="number"
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Notes">
              <FloatingLabel
                controlId="floatingInput"
                label="Notes"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Notes"
                  type="textarea"
                  value={Notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="Scanner">
              <div className="floatingQRContainer">
                <div className="floatingQR">
                  <button
                    type="button"
                    className="trashCls"
                    onClick={() => handleShow(0)}
                  >
                    <BsUpcScan size={25} />
                  </button>
                </div>
              </div>
            </Form.Group>
            <LoaderButton
              block="true"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
              className="format"
              disabled={!validateForm()}
            >
              Start Recipe
            </LoaderButton>
          </Form>
        )}
        <Modal
          show={QRshow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Scanner
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={async (result, error) => {
                if (!!result) {
                  await loadScannedEntry(result.text);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              style={{ width: "100%" }}
            />
          </Modal.Body>
          <Modal.Footer>
            {isScannerLoading ? (
              <LoaderButton
                block="true"
                type="submit"
                size="lg"
                variant="primary"
                isLoading={isScannerLoading}
                className="format"
                disabled={true}
              >
                Loading...
              </LoaderButton>
            ) : null}
            {scannerErrorMessage ? (
              <div className="justify-content-center alert alert-danger">
                Scanned Item already exist in the database
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import {
  FloatingLabel,
  Accordion,
  Table,
  Modal,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import config from "../../config";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import "./CreateAX1MEntry.css";

export default function CreateAX1MEntry() {
  const file = useRef(null);
  const history = useHistory();
  const [currentlabnumber, setcurrentlabnumber] = useState(0);
  const [QRCodeLinked, setQRCodeLinked] = useState("");
  const [BASALRECIPEID, setBASALRECIPEID] = useState("");
  const [MediaEntry, setMediaEntry] = useState(null);
  const [AX1FarmEntry1, setAX1FarmEntry1] = useState(null);
  const [AX1FarmEntry2, setAX1FarmEntry2] = useState(null);
  const [AX1FarmEntry3, setAX1FarmEntry3] = useState(null);

  const [FarmBatchIDArray, setFarmBatchIDArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const [isScannerLoading, setIsScannerLoading] = useState(false);

  const handleClose = () => setQRshow(false);
  const handleShow = (i) => {
    setcurrentlabnumber(i);
    setscannerErrorMessage(false);
    setQRshow(true);
  };

  async function handleFarmData(data) {
    if (data.FarmBatchIDs !== undefined) {
      let fData = [];
      for (let i = 0; i < data.FarmBatchIDs.length; i++) {
        const farmData = await API.get(
          "omeat",
          `/FARM/entries/${encodeURIComponent(data.FarmBatchIDs[i])}`
        );
        fData.push(farmData);
      }
      data["farmEntriesData"] = fData;
      return data;
    } else {
      let fData = [];
      const farmData = await API.get(
        "omeat",
        `/FARM/entries/${encodeURIComponent(data[0].sk)}`
      );
      fData.push(farmData);
      data["farmEntriesData"] = fData;
      return data;
    }
  }

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      setscannerErrorMessage(false);
      setIsScannerLoading(true);
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0 && currentlabnumber !== 0) {
        if (scannedID[0].GSI1SK) {
          if (
            scannedID[0].GSI1SK.split("#")[0] === "FARM" ||
            scannedID[0].GSI1SK.split("#")[0] === "BARREL"
          ) {
            var farmBatchIDSample = "";
            var farmBatchID;
            var scannedLabID;
            const farmBatchIDentries = await API.get(
              "omeat",
              `/allentries/${encodeURIComponent(scannedID[0].GSI1SK)}`
            );
            console.log(farmBatchIDentries);

            for (var i = 0; i < farmBatchIDentries.length; i++) {
              if (
                farmBatchIDentries[i].sk.includes("LAB#") &&
                (farmBatchIDentries[i].entryType === "sample" ||
                  farmBatchIDentries[i].entryType === "barrel" ||
                  farmBatchIDentries[i].entryType === "")
              ) {
                farmBatchIDSample = farmBatchIDentries[i];
              }
            }
            if (farmBatchIDSample) {
              farmBatchID = farmBatchIDSample;
            } else {
              if (scannedID[0].GSI1SK.includes("BARREL")) {
                farmBatchID = farmBatchIDentries[0];
              }
            }
            console.log(currentlabnumber);
            if (farmBatchID && farmBatchID.sk) {
              scannedLabID = await API.get(
                "omeat",
                `/LAB/entries/${encodeURIComponent(farmBatchID.sk)}`
              );
              console.log(scannedLabID)
              if (currentlabnumber === 2) {
                setFarmBatchIDArray((FarmBatchIDArray) => [
                  ...FarmBatchIDArray,
                  scannedLabID.sk,
                ]);
                const cmpleteData = await handleFarmData(scannedLabID);
                setAX1FarmEntry1(cmpleteData);
              } else {
                if (currentlabnumber === 3) {
                  setFarmBatchIDArray((FarmBatchIDArray) => [
                    ...FarmBatchIDArray,
                    scannedLabID.sk,
                  ]);
                  const cmpleteData = await handleFarmData(scannedLabID);
                  setAX1FarmEntry2(cmpleteData);
                } else {
                  if (currentlabnumber === 4) {
                    setFarmBatchIDArray((FarmBatchIDArray) => [
                      ...FarmBatchIDArray,
                      scannedLabID.sk,
                    ]);
                    const cmpleteData = await handleFarmData(scannedLabID);
                    console.log(cmpleteData);
                    setAX1FarmEntry3(cmpleteData);
                  }
                }
              }
            }
          } else if (scannedID[0].GSI1SK.split("#")[0] === "LAB") {
            const recipeData = await API.get(
              "omeat",
              `/LAB/RECIPE/entries/${encodeURIComponent(scannedID[0].sk)}`
            );
            setMediaEntry(scannedID[0]);
            setBASALRECIPEID(recipeData.sk);
          }
          handleClose();
          setIsScannerLoading(false);
        }
      } else {
        if (currentlabnumber === 0) {
          if (scannedID.length > 0 && !scannedID[0].GSI1SK) {
            setIsScannerLoading(false);
            setscannerErrorMessage(true);
          } else {
            setQRCodeLinked(QRCodeLinked);
            handleClose();
          }
        } else {
          setIsScannerLoading(false);
          setscannerErrorMessage(true);
        }
      }
    }
  }

  function validateForm() {
    return QRCodeLinked.length > 0 && AX1FarmEntry1 != null;
  }

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  function updateEntry(Item) {
    return API.post("omeat", `/LAB/AX1M/entries`, {
      body: Item,
    });
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      console.log("File size error");
      return;
    }
    setIsLoading(true);

    var FarmBatchIDs = FarmBatchIDArray.filter(onlyUnique);

    const updateEntryData = {
      AX1MQRCodeLinked: QRCodeLinked,
      BASALRECIPEID: BASALRECIPEID,
      AX1BATCHIDs: [...FarmBatchIDs],
    };

    await updateEntry(updateEntryData);

    setIsLoading(false);
    history.push("/lab/AX1M");
  }

  function showLab(AX1FarmEntry) {
    return (
      <>
        <Accordion className="scannerC mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {AX1FarmEntry.sk
                ? AX1FarmEntry.sk
                : AX1FarmEntry.QRCodeLinked
                ? AX1FarmEntry.QRCodeLinked
                : AX1FarmEntry.FarmBatchIDQRs[0]}
            </Accordion.Header>
            <Accordion.Body>
              <Table className="tableDiv">
                <tbody>
                  <tr>
                    <td>Barrel ID</td>
                    <td className="fw-bold word-break">
                      {AX1FarmEntry.BarrelQRCodeLinked}
                    </td>
                  </tr>
                  <tr>
                    <td>Date Tested (Day -1)</td>
                    <td className="fw-bold">
                      {AX1FarmEntry.DateTestedDayMinus1}
                    </td>
                  </tr>
                  <tr>
                    <td>BacterialCont1?</td>
                    <td className="fw-bold">{AX1FarmEntry.BacterialCont1}</td>
                  </tr>
                  <tr>
                    <td>Colony Forming Units</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry.ColonyFormingUnits1}
                    </td>
                  </tr>
                  <tr>
                    <td>Date tested (Day-2) AX1 and AX1+Media(50%)</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry.DateTestedDayMinus2}
                    </td>
                  </tr>
                  <tr>
                    <td>BacterialCont2?</td>
                    <td className="fw-bold"> {AX1FarmEntry.BacterialCont2}</td>
                  </tr>
                  <tr>
                    <td>Colony Forming Units2</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry.ColonyFormingUnits2}
                    </td>
                  </tr>
                  <tr>
                    <td>Date Tested (Day -3)</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry.DateTestedDayMinus3}
                    </td>
                  </tr>
                  <tr>
                    <td>BacterialCont3?</td>
                    <td className="fw-bold"> {AX1FarmEntry.BacterialCont3}</td>
                  </tr>
                  <tr>
                    <td>Colony Forming Units</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry.ColonyFormingUnits3}
                    </td>
                  </tr>
                  <tr>
                    <td>Clottable Protein Content</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry.ClottableProteinContent}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Protein</td>
                    <td className="fw-bold"> {AX1FarmEntry.TotalProtein}</td>
                  </tr>
                  <tr>
                    <td>Lab Tech</td>
                    <td className="fw-bold"> {AX1FarmEntry.LabTech}</td>
                  </tr>
                  <tr>
                    <td>Notes</td>
                    <td className="fw-bold"> {AX1FarmEntry.Notes}</td>
                  </tr>
                  <tr>
                    <td>Attachment</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry.attachment && (
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={AX1FarmEntry.attachmentURL}
                          >
                            {formatFilename(AX1FarmEntry.attachment)}
                          </a>
                        </p>
                      )}
                    </td>
                  </tr>
                </tbody>
                {AX1FarmEntry.farmEntriesData.length > 0 &&
                  showFarm(AX1FarmEntry.farmEntriesData)}
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }

  function showMedia(MediaEntry) {
    return (
      <>
        <Accordion className="scannerC mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{BASALRECIPEID}</Accordion.Header>
            <Accordion.Body>
              <Table className="tableDiv">
                <tbody>
                  <tr>
                    <td>QRLinked</td>
                    <td className="fw-bold">{MediaEntry.QRcode}</td>
                  </tr>
                  <tr>
                    <td>Created By</td>
                    <td className="fw-bold">{MediaEntry.createdBy}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td className="fw-bold">{MediaEntry.createdDate}</td>
                  </tr>
                  <tr>
                    <td>Created Time</td>
                    <td className="fw-bold">{MediaEntry.createdTime}</td>
                  </tr>
                  <tr>
                    <td>Volume</td>
                    <td className="fw-bold">{MediaEntry.volume}</td>
                  </tr>
                  <tr>
                    <td>End Date</td>
                    <td className="fw-bold">{MediaEntry.endDate}</td>
                  </tr>
                  <tr>
                    <td>End Time</td>
                    <td className="fw-bold">{MediaEntry.endTime}</td>
                  </tr>
                  <tr>
                    <td>Notes</td>
                    <td className="fw-bold"> {MediaEntry.Notes}</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }

  function showFarm(data) {
    return (
      <>
        {data.map((details, index) => {
          return (
            <tbody key={index}>
              <tr>
                <td>CowName</td>
                <td className="fw-bold">{details.CowName}</td>
              </tr>
              <tr>
                <td>Collection Date</td>
                <td className="fw-bold">{details.CollectionDate}</td>
              </tr>
              <tr>
                <td>Collection Volume</td>
                <td className="fw-bold">{details.CollectionVolume}</td>
              </tr>
            </tbody>
          );
        })}
      </>
    );
  }

  return (
    <>
      <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
        {1 && (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="Scanner">
              <>
                <button type="button" onClick={() => handleShow(0)}>
                  <BsUpcScan size={30} />
                </button>

                <p>{QRCodeLinked}</p>
              </>
            </Form.Group>
            <Container className="scannerC">
              <Row>
                <Col className="scanner">
                  <Form.Group controlId="QRCodeLinked">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Media QRCode"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="Media QRCodeLinked"
                        disabled
                        type="text"
                        value={MediaEntry?.QRcode ? MediaEntry?.QRcode : ""}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={2} md={1}>
                  <button type="button" onClick={() => handleShow(1)}>
                    <BsUpcScan size={15} />
                  </button>
                </Col>
              </Row>
              {MediaEntry && showMedia(MediaEntry)}
            </Container>
            <Container className="scannerC">
              <Row>
                <Col className="scanner">
                  <Form.Group controlId="AX1FarmBatchID">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="AX1 1 QR Code"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="AX1FarmBatchID"
                        type="text"
                        disabled
                        value={
                          AX1FarmEntry1?.BarrelQRCodeLinked
                            ? AX1FarmEntry1?.BarrelQRCodeLinked
                            : AX1FarmEntry1?.QRCodeLinked
                            ? AX1FarmEntry1?.QRCodeLinked
                            : AX1FarmEntry1?.FarmBatchIDQRs[0]
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={2} md={1}>
                  <button type="button" onClick={() => handleShow(2)}>
                    <BsUpcScan size={15} />
                  </button>
                </Col>
              </Row>
              {AX1FarmEntry1 && showLab(AX1FarmEntry1)}
            </Container>
            <Container className="scannerC">
              <Row>
                <Col className="scanner">
                  <Form.Group controlId="AX1FarmBatchID2">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="AX1 2 QR Code"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="AX1FarmBatchID2"
                        type="text"
                        disabled
                        value={
                          AX1FarmEntry2?.BarrelQRCodeLinked
                            ? AX1FarmEntry2?.BarrelQRCodeLinked
                            : AX1FarmEntry2?.QRCodeLinked
                            ? AX1FarmEntry2?.QRCodeLinked
                            : AX1FarmEntry2?.FarmBatchIDQRs[0]
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={2} md={1}>
                  <button type="button" onClick={() => handleShow(3)}>
                    <BsUpcScan size={15} />
                  </button>
                </Col>
              </Row>
              {AX1FarmEntry2 && showLab(AX1FarmEntry2)}
            </Container>
            <Container className="scannerC">
              <Row>
                <Col className="scanner">
                  <Form.Group controlId="AX1FarmBatchID3">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="AX1 3 QR Code"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="AX1FarmBatchID3"
                        type="text"
                        disabled
                        value={
                          AX1FarmEntry3?.BarrelQRCodeLinked
                            ? AX1FarmEntry3?.BarrelQRCodeLinked
                            : AX1FarmEntry3?.QRCodeLinked
                            ? AX1FarmEntry3?.QRCodeLinked
                            : AX1FarmEntry3?.FarmBatchIDQRs[0]
                        }
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={2} md={1}>
                  <button type="button" onClick={() => handleShow(4)}>
                    <BsUpcScan size={15} />
                  </button>
                </Col>
              </Row>
              {AX1FarmEntry3 && showLab(AX1FarmEntry3)}
            </Container>
            <LoaderButton
              block="true"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
              className="format"
              disabled={!validateForm()}
            >
              Create
            </LoaderButton>
          </Form>
        )}
        <Modal
          show={QRshow}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Scanner
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QrReader
              constraints={{ facingMode: "environment" }}
              onResult={async (result, error) => {
                if (!!result) {
                  await loadScannedEntry(result.text);
                  //handleClose();
                }

                if (!!error) {
                  // console.info(error);
                }
              }}
              style={{ width: "100%" }}
            />
          </Modal.Body>
          <Modal.Footer>
            {isScannerLoading ? (
              <LoaderButton
                block="true"
                type="submit"
                size="lg"
                variant="primary"
                isLoading={isScannerLoading}
                className="format"
                disabled={true}
              >
                Loading...
              </LoaderButton>
            ) : null}
            {scannerErrorMessage ? (
              <div className="justify-content-center alert alert-danger">
                Scanned Item doesn't exist in the database
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
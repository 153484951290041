import React, { useState, useEffect, useMemo } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./FarmHome.css";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Button, Modal, FloatingLabel, Accordion } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";

let PageSize = 10;

export default function FarmHome() {
  const [entries, setEntries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cowNames, setCowNameList] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showonlyUserEntry, setshowonlyUserEntry] = useState(true);
  const [QRshow, setQRshow] = useState(false);
  const [CowName, setCowName] = useState("");
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const handleClose = () => setQRshow(false);
  const handleShow = () => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };

  const history = useHistory();

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0) {
        if (scannedID[0].pk) {
          let path = `/FARM/entries/${encodeURIComponent(scannedID[0].sk)}`;
          history.push(path);
        }
      } else {
        setscannerErrorMessage(true);
      }
    }
  }

  async function getCows() {
    const cowID = encodeURIComponent("FARM#SETUP$DRAKE#COWS");
    return await API.get("omeat", `/FARM/SETUP/${cowID}`);
  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const entries = await loadDefUserEntries();
        const cows = await getCows();
        setEntries(entries);
        setCowNameList(cows);
        console.log(entries);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return entries.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, entries]);

  console.log(currentTableData)
  async function loadDefUserEntries() {
    return API.get("omeat", "/FARM/entriesuser");
  }

  async function loadUserEntries(CowName) {
    if (CowName) {
      let encCowName = encodeURIComponent(CowName);
      return API.get("omeat", `/FARM/entriesuser?cowName=${encCowName}`);
    } else {
      return API.get("omeat", "/FARM/entriesuser");
    }
  }

  function loadAllEntries(CowName) {
    if (CowName) {
      let encCowName = encodeURIComponent(CowName);
      return API.get("omeat", `/FARM/entriesall?cowName=${encCowName}`);
    } else {
      return API.get("omeat", "/FARM/entriesall");
    }
  }

  const filterResults = async (event) => {
    event.preventDefault();
    try {
      console.log("showonlyUserEntry:" + showonlyUserEntry);
      if (showonlyUserEntry) {
        var entries = await loadUserEntries(CowName);
      } else {
        entries = await loadAllEntries(CowName);
      }

      setEntries(entries);
      //setshowonlyUserEntry(!showonlyUserEntry);
    } catch (e) {
      onError(e);
    }
    console.log("now calling API");
  };

  function renderEntriesList(entries) {
    return (
      <>
        <LinkContainer to="/farm/entries/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new entry</span>
          </ListGroup.Item>
        </LinkContainer>

        {currentTableData && currentTableData.map(({ sk, Title, LeadTech, createdAt }, index) => (
          <LinkContainer
            key={index}
            to={`/farm/entries/${encodeURIComponent(sk)}`}
          >
            <ListGroup.Item action>
              <span className="font-weight-bold">{Title}</span>
              <br />
              <span className="text-muted">
                Lead Tech: {LeadTech}, Created:{" "}
                {createdAt > 0
                  ? new Date(createdAt).toLocaleDateString() +
                    " " +
                    new Date(createdAt).toLocaleTimeString()
                  : ""}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={entries.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Omeat</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>
      </div>
    );
  }

  function handleCheck() {
    setshowonlyUserEntry(!showonlyUserEntry);
  }

  function renderEntries() {
    return (
      <div className="entries">
        <button type="button" onClick={handleShow}>
          <BsUpcScan size={30} />
        </button>
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Farm Entries </h2>
        <Accordion className="mb-3" defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filters </Accordion.Header>
            <Accordion.Body>
              <div className="filters mt-1 mb-5 border border-primary rounded">
                <div className="mt-3 mb-3 mx-3">
                  <Form className onSubmit={filterResults}>
                    <Form.Check
                      type="switch"
                      id="showonlyUserEntry"
                      label="Show only my entries?"
                      className="mb-3"
                      defaultChecked={showonlyUserEntry}
                      onChange={handleCheck}
                    />
                    <Form.Group controlId="CowName">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Cow Name"
                        className="mb-3"
                      >
                        <Form.Select
                          className="mt-3"
                          value={CowName}
                          aria-label="Default select example"
                          onChange={(e) => setCowName(e.target.value)}
                        >
                          <option> </option>
                          {cowNames.map((name, index) => (
                            <option key={index} value={name}>
                              {name}
                            </option>
                          ))}
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                    <LoaderButton
                      block="true"
                      type="submit"
                      size="sm"
                      variant="primary"
                      isLoading={isLoading}
                      className="format"
                    >
                      Apply
                    </LoaderButton>
                  </Form>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <ListGroup>{!isLoading && renderEntriesList(entries)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderEntries() : renderLander()}
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                await loadScannedEntry(result.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {scannerErrorMessage ? (
            <div className="alert alert-danger">
              Scanned Item doesn't exist in the database
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import { FloatingLabel, Table, Container, Modal } from "react-bootstrap";
import "./DeliveryEntries.css";
import LoadingOverlay from "react-loading-overlay";
import More from "../../images/more.png";

export default function DeliveryEntries() {
  const { id } = useParams();
  const [isActive, setisActive] = useState(true);
  const handleShow = () => {
    setscannerErrorMessage(false);
    setQRshow(true);
  };
  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const handleClose = () => setQRshow(false);

  const [shipData, setShipData] = useState({
    createdBy: "",
    createdAt: "",
    shipDate: "",
    shipTime: "",
    Notes: "",
  });
  const [ScannedQRArray, setScannedQRArray] = useState([]);
  const [receivedData, setReceivedData] = useState({
    receivedBy: "",
    receivedDate: "",
    receivedTime: "",
    recevingNotes: "",
  });

  useEffect(() => {
    function loadShipEntry() {
      return API.get("omeat", `/SHIP/entries/${id}`);
    }

    async function onLoad() {
      try {
        const shipEntry = await loadShipEntry();
        console.log(shipEntry);
        setScannedQRArray(shipEntry.FarmBatchIDs);
        setShipData({
          shipDate: shipEntry.shipDate,
          createdBy: shipEntry.createdBy,
          shipTime: shipEntry.shipTime,
          createdAt: shipEntry.createdAt,
          Notes: shipEntry.Notes,
        });
        setisActive(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  return (
    <>
      <LoadingOverlay active={isActive} spinner text="Loading...">
        <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
          {1 && (
            <Form>
              <Form.Group controlId="Shipping Date">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping Date"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="Shipping Date"
                    type="date"
                    value={shipData.shipDate}
                    disabled="true"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="Shipping Time">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping Time"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="Shipping Time"
                    type="time"
                    value={shipData.shipTime}
                    disabled="true"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="Created By">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Created By"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="Created By"
                    type="text"
                    value={shipData.createdBy}
                    disabled="true"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="Created Date">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Created Date"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="Created Date"
                    type="date"
                    value={shipData.createdAt}
                    disabled="true"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="Shipping Notes">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping Notes"
                  className="mb-3"
                >
                  <Form.Control
                    placeholder="Shipping Notes"
                    type="text"
                    value={shipData.Notes}
                    disabled
                  />
                </FloatingLabel>
              </Form.Group>
              <Container className="scannerC">
                {ScannedQRArray.length > 0 && (
                  <>
                    {" "}
                    <Table className="tableDiv">
                      <tbody>
                        <tr>
                          <td className="fw-bold text-left">QR Code</td>
                          <td className="fw-bold text-center">Received</td>
                          <td className="fw-bold text-center">Details</td>
                        </tr>
                        {ScannedQRArray.map((qr, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ textAlign: "left" }}>{qr.qr}</td>
                              <td className="fw-bold imgData">
                                <img
                                  src={
                                    qr.received === "N"
                                      ? require(`../../images/cross.png`)
                                      : require(`../../images/check.png`)
                                  }
                                  width="20"
                                  height="20"
                                  className=""
                                  alt=" "
                                />
                              </td>
                              <td className="fw-bold imgData">
                                <img
                                  src={More}
                                  width="20"
                                  height="20"
                                  className=""
                                  alt=" "
                                  onClick={() => {
                                    setReceivedData(qr);
                                    handleShow();
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                )}
              </Container>
            </Form>
          )}
          <Modal
            show={QRshow}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Receiver Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="detailsAlign">
                <b>Received By:</b>
                {receivedData.receivedBy}
              </div>
              <div className="detailsAlign">
                <b>Received At:</b>
                {receivedData.receivedTime}
              </div>
              <div className="detailsAlign">
                <b>Received Date:</b>
                {receivedData.receivedDate}
              </div>
              <div className="detailsAlign">
                <b>Received Notes:</b>
                {receivedData.recevingNotes}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {scannerErrorMessage ? (
                <div className="justify-content-center alert alert-danger">
                  Scanned Item doesn't exist in the database
                </div>
              ) : null}
            </Modal.Footer>
          </Modal>
        </div>
      </LoadingOverlay>
    </>
  );
}
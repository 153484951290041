import React from "react";
import { Grid, Box } from "@mui/material";
import PageContainer from "./../../components/container/PageContainer";

// components
import CollectionVolume from "./components/CollectionVolume";
import CollectionVolumeTotal from "./components/CollectionVolumeTotal";
import CollectionVolumeByCow from "./components/CollectionVolumeByCow";

const Dashboard = () => {
  return (
    <PageContainer title="Dashboard1" description="this is Dashboard">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={7}>
            <CollectionVolume />
            {/* <Bar /> */}
          </Grid>
          <Grid item xs={12} lg={5}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CollectionVolumeTotal />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <CollectionVolumeByCow />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Dashboard;
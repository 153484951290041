import React, { useState, useEffect } from "react";
import { onError } from "../../../../../lib/errorLib";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../../../LoaderButton";
import { useAppContext } from "../../../../../lib/contextLib";
import { FloatingLabel } from "react-bootstrap";
import { API } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";

export default function CowEntries(props) {
  const [cows, setCows] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [cowNames, setCowNameList] = useState([]);
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  function validateForm() {
    return cows !== undefined && cows !== "" ? true : false;
  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        if (id) {
          setCows(decodeURIComponent(id));
        }
        const cows = await getCows();
        setCowNameList(cows);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated,id]);

  async function getCows() {
    const cowID = encodeURIComponent("FARM#SETUP$DRAKE#COWS");
    return await API.get("omeat", `/FARM/SETUP/${cowID}`);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    decodeURIComponent(id) !== undefined &&
    decodeURIComponent(id) !== "undefined" &&
    decodeURIComponent(id) !== ""
      ? await updateCows()
      : await addCows();
    console.log(cows);
  }

  async function addCows() {
    const names = [...cowNames, cows];
    sendCowListToAPI(names);
  }

  async function updateCows() {
    const names = cowNames.map((name) => {
      if (name === decodeURIComponent(id)) {
        name = cows;
      }
      return name;
    });

    sendCowListToAPI(names);
  }

  async function sendCowListToAPI(item) {
    const Item = {
      VALUES: item,
    };
    const cowID = encodeURIComponent("FARM#SETUP$DRAKE#COWS");

    await API.put("omeat", `/FARM/SETUP/${cowID}`, {
      body: Item,
    });
    setIsLoading(false);
    history.push("/farmadmin/cow");
  }

  return (
    <>
      <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
        {
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="Cows">
              <FloatingLabel
                controlId="floatingInput"
                label="Cow Name"
                className="mb-3"
              >
                <Form.Control
                  placeholder="Cow Name"
                  type="text"
                  value={cows}
                  onChange={(e) => setCows(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
            <LoaderButton
              block="true"
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
              className="format"
              disabled={!validateForm()}
            >
              {decodeURIComponent(id) !== undefined &&
              decodeURIComponent(id) !== "undefined" &&
              decodeURIComponent(id) !== ""
                ? "Update"
                : "Submit"}
            </LoaderButton>
          </Form>
        }
      </div>
    </>
  );
}

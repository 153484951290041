import React, { useState, useEffect } from "react";
import { onError } from "../../lib/errorLib";
import { API } from "aws-amplify";
import "./RecipeEntries.css";
import { useHistory } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { FloatingLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";

export default function CreateRecipeEntry() {
  const { id } = useParams();
  const [tableData, settableData] = useState([]);
  const history = useHistory();
  const [stepData, setstepData] = useState(null);
  const [stepIncr, setstepIncr] = useState(0);
  const [reset, setreset] = useState(null);
  const [result, setresult] = useState(null);
  const [completedSteps, setcompletedSteps] = useState([]);
  const [isActive, setisActive] = useState(true);
  const [endTime, setEndTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [createdTime, setCreatedTime] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const [volume, setVolume] = useState(1);
  const [qrLinked, setQrLinked] = useState("");
  const [Notes, setNotes] = useState("");
  const [showComplete, setshowComplete] = useState(false);

  var Item;

  const handleClose = async () => {
    let d = new Date();
    const endDate = d.toISOString().split("T")[0];
    const endTime =
      (d.getHours() > 9 ? d.getHours() : "0" + d.getHours()) +
      ":" +
      (d.getMinutes() > 9 ? d.getMinutes() : "0" + d.getMinutes());  

    var Item = {
      endDate: endDate,
      endTime: endTime,
      Notes: Notes,
      completedSteps
    };

    await API.put("omeat", `/LAB/RECIPE/entries/${encodeURIComponent(id)}`, {
      body: Item,
    });
    history.push("/lab/recipes");
  };
  useEffect(() => {
    console.log(id);

    function loadRecipeEntry() {
      return API.get("omeat", `/LAB/RECIPE/entries/${id}`);
    }

    async function onLoad() {
      try {
        const recipeEntry = await loadRecipeEntry();
        console.log(recipeEntry);
        console.log(recipeEntry.completedSteps);
        setcompletedSteps(recipeEntry.completedSteps);
        setCreatedBy(recipeEntry.createdBy);
        setCreatedTime(recipeEntry.createdTime);
        setCreatedDate(recipeEntry.createdDate);
        setEndTime(recipeEntry.endTime);
        setEndDate(recipeEntry.endDate);        
        setVolume(recipeEntry.volume);
        setQrLinked(recipeEntry.QRcode);
        setNotes(recipeEntry.Notes);
        const dataTable = await API.get("omeat", `/LAB/recipesteps?volume=` + recipeEntry.volume);
        console.log(
          recipeEntry.completedSteps.length + 1,
          Object.keys(dataTable).length - 2
        );
        if (
          recipeEntry.completedSteps.length + 1 ===
          Object.keys(dataTable).length - 2
        ) {
        }
        for (
          var i = recipeEntry.completedSteps.length + 1;
          i < Object.keys(dataTable).length - 2;
          i++
        ) {
          console.log(
            dataTable[i][0].value +
            " " +
            dataTable[i][1].value +
            " " +
            dataTable[i][2].value +
            " " +
            dataTable[i][3].value +
            " of " +
            dataTable[i][4].value
          );
          tableData.push(
            dataTable[i][2].value +
            "###" +
            dataTable[i][0].value +
            " " +
            dataTable[i][1].value +
            " " +
            dataTable[i][2].value +
            " " +
            dataTable[i][3].value +
            " of " +
            dataTable[i][4].value
          );
        }
        settableData(tableData);
        setstepIncr(0);
        if (tableData[0]) {
          setstepData(tableData[0].split("###")[1]);
        }

        setisActive(false);
        //console.log("completedSteps:" + completedSteps);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [tableData, id]);

  async function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    var weight = Number(data.get("weight"));
    console.log("weight:" + weight, data.get("weight"));

    if (Number(tableData[stepIncr].split("###")[0]) === weight) {
      setcompletedSteps((completedSteps) => [
        ...completedSteps,
        tableData[stepIncr].split("###")[1],
      ]);
      Item = {
        endDate: endDate,
        endTime: endTime,
        Notes: Notes,
        completedSteps: [
          ...completedSteps,
          tableData[stepIncr].split("###")[1],
        ],
      };

      await API.put("omeat", `/LAB/RECIPE/entries/${encodeURIComponent(id)}`, {
        body: Item,
      });

      var step = stepIncr + 1;
      setstepIncr(step);
      console.log(tableData.length, step);
      if (step < tableData.length) {
        setstepData(tableData[step].split("###")[1]);
      } else {
        setshowComplete(true);
      }

      setresult("");
    } else {
      setresult("Weight did not match!");
    }
    setreset("");

    cancelwform();
  }

  var cancelwform = () => {
    document.getElementById("wform").reset();
  };

  const content = () => {
    return (
      <>
        {" "}
        <Form id="wform" onSubmit={handleSubmit}>
          {showComplete ? null :
            <input
              autoFocus
              className="mt-5 w-25"
              type="text"
              name="weight"
              placeholder={reset}
            />}
          <Button className="mt-5 Invbutton " type="submit" name="weight2" />

        </Form>
        <h3 className="text-danger">{result}</h3>
      </>
    );
  };
  return (
    <div>
      <LoadingOverlay active={isActive} spinner text="Loading...">
        <Form.Group controlId="QR Linked">
          <FloatingLabel
            controlId="floatingInput"
            label="QR Linked"
            className="mb-3"
          >
            <Form.Control
              placeholder="QR Linked"
              type="text"
              value={qrLinked}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="Created By">
          <FloatingLabel
            controlId="floatingInput"
            label="Created By"
            className="mb-3"
          >
            <Form.Control
              placeholder="Created By"
              type="text"
              value={createdBy}
              disabled
            />
          </FloatingLabel>
        </Form.Group>        
        <Form.Group controlId="Created Date">
          <FloatingLabel
            controlId="floatingInput"
            label="Created Date"
            className="mb-3"
          >
            <Form.Control
              placeholder="Created Date"
              type="date"
              value={createdDate}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="Created Time">
          <FloatingLabel
            controlId="floatingInput"
            label="Created Time"
            className="mb-3"
          >
            <Form.Control
              placeholder="Created Time"
              type="time"
              value={createdTime}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="Volume">
          <FloatingLabel
            controlId="floatingInput"
            label="Volume"
            className="mb-3"
          >
            <Form.Control
              placeholder="Volume"
              type="number"
              value={volume}
              disabled
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="End Date">
          <FloatingLabel
            controlId="floatingInput"
            label="End Date"
            className="mb-3"
          >
            <Form.Control
              placeholder="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="End Time">
          <FloatingLabel
            controlId="floatingInput"
            label="End Time"
            className="mb-3"
          >
            <Form.Control
              placeholder="End Time"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="Notes">
          <FloatingLabel
            controlId="floatingInput"
            label="Notes"
            className="mb-3"
          >
            <Form.Control
              placeholder="Notes"
              type="textarea"
              value={Notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </FloatingLabel>
        </Form.Group>
        <h1>{stepData ? stepData : null}</h1>
        {stepData ? content() : null}
        {showComplete ? <LoaderButton className="color-nav" onClick={handleClose}>Complete Recipe</LoaderButton> : null}
        <Table className="mt-5 text-muted table table-bordered">
          <tbody>
            <tr>
              <th>Completed Steps...</th>
            </tr>

            {completedSteps.map((stps, index) => (
              <tr data-index={index}>
                <td>{stps}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </LoadingOverlay>
    </div>
  );
}
import React from "react";
import Card from "../Card/Card";
import { Link } from "react-router-dom";

export default function NavLinkData(props) {
  return (
    <Link key={props.index} to={`${props.data.link}`} className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
      <Card className="linkClass">
        <img
          src={require(`../../images/${props.data.img}`)}
          width="45"
          height="45"
          className=""
          alt=" "
        />
        {props.data.linkName}
      </Card>
    </Link>
  );
}

import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import { API, Storage } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import {
  FloatingLabel,
  Accordion,
  Table,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { s3Upload } from "../../lib/awsLib";
import config from "../../config";
import { QrReader } from "react-qr-reader";
import { BsUpcScan } from "react-icons/bs";
import LoadingOverlay from "react-loading-overlay";
import "./LabEntries.css";
LoadingOverlay.propTypes = undefined;

export default function LabEntries() {
  const file = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [isActive, setisActive] = useState(true);
  const [currentfarmnumber, setcurrentfarmnumber] = useState(0);
  const [BarrelIDLinked, setBarrelIDLinked] = useState("");
  const [AX1FarmEntry1, setAX1FarmEntry1] = useState(null);
  const [AX1FarmEntry2, setAX1FarmEntry2] = useState(null);
  const [AX1FarmEntry3, setAX1FarmEntry3] = useState(null);
  const [AX1FarmBatchID, setAX1FarmBatchID] = useState("");
  const [AX1FarmBatchID2, setAX1FarmBatchID2] = useState("");
  const [AX1FarmBatchID3, setAX1FarmBatchID3] = useState("");
  const [LabEntry, setLabEntry] = useState(null);
  const [LabTech, setLabTech] = useState("");
  const [DateTestedDayMinus1, setDateTestedDayMinus1] = useState("");
  let [BacterialContColonyFormingUnits1, setBacterialContColonyFormingUnits1] =
    useState("");
  let [yeastAndMoldColonyFormingUnits1, setyeastAndMoldColonyFormingUnits1] =
    useState("");
  let [BacterialCont1, setBacterialCont1] = useState(false);
  const [DateTestedDayMinus2, setDateTestedDayMinus2] = useState("");
  let [BacterialContColonyFormingUnits2, setBacterialContColonyFormingUnits2] =
    useState("");
  let [yeastAndMoldColonyFormingUnits2, setyeastAndMoldColonyFormingUnits2] =
    useState("");
  let [BacterialCont2, setBacterialCont2] = useState(false);
  const [DateTestedDayMinus3, setDateTestedDayMinus3] = useState("");
  let [BacterialContColonyFormingUnits3, setBacterialContColonyFormingUnits3] =
    useState("");
  let [yeastAndMoldColonyFormingUnits3, setyeastAndMoldColonyFormingUnits3] =
    useState("");
  let [BacterialCont3, setBacterialCont3] = useState(false);
  // const [DateTestedDayMinus4, setDateTestedDayMinus4] = useState("");
  // let [ColonyFormingUnits4, setColonyFormingUnits4] = useState("");
  // let [BacterialCont4, setBacterialCont4] = useState(false);
  let [CellGrowthEfficiency, setCellGrowthEfficiency] = useState("");

  const [ClottableProteinContent, setClottableProteinContent] = useState("");
  const [TotalProtein, setTotalProtein] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let [DONOTUSE, setDoNotUse] = useState(false);
  let [yeastAndMold1, setYeastandMold1] = useState(false);
  let [yeastAndMold2, setYeastandMold2] = useState(false);
  let [yeastAndMold3, setYeastandMold3] = useState(false);
  // let [yeastAndMold4, setYeastandMold4] = useState(false);

  const [Notes, setNotes] = useState("");
  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const handleClose = () => setQRshow(false);
  const handleShow = (i) => {
    setcurrentfarmnumber(i);
    setscannerErrorMessage(false);
    setQRshow(true);
  };

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0) {
        if (scannedID[0].GSI1SK) {
          var attachment = scannedID[0].attachment;

          if (attachment) {
            scannedID[0].attachmentURL = await Storage.get(attachment);
          }
          if (currentfarmnumber === 1) {
            setAX1FarmBatchID(scannedID[0].sk);
            setAX1FarmEntry1(scannedID[0]);
          } else {
            if (currentfarmnumber === 2) {
              setAX1FarmBatchID2(scannedID[0].sk);
              setAX1FarmEntry2(scannedID[0]);
            } else {
              if (currentfarmnumber === 3) {
                setAX1FarmBatchID3(scannedID[0].sk);
                setAX1FarmEntry3(scannedID[0]);
              }
            }
          }
          handleClose();
        }
      } else {
        setscannerErrorMessage(true);
      }
    }
  }

  useEffect(() => {
    function loadLabEntry() {
      return API.get("omeat", `/LAB/entries/${id}`);
    }

    function loadFarmEntry(AX1FarmBatchID) {
      return API.get(
        "omeat",
        `/FARM/entries/${encodeURIComponent(AX1FarmBatchID)}`
      );
    }

    async function onLoad() {
      try {
        const labEntry = await loadLabEntry();
        if (labEntry.AX1FarmBatchID) {
          const farmEntry = await loadFarmEntry(labEntry.AX1FarmBatchID);
          if (farmEntry.attachment) {
            farmEntry.attachmentURL = await Storage.get(farmEntry.attachment);
          }
          if (farmEntry.attachment2) {
            farmEntry.attachment2URL = await Storage.get(farmEntry.attachment2);
          }
          if (farmEntry.attachment3) {
            farmEntry.attachment3URL = await Storage.get(farmEntry.attachment3);
          }
          if (farmEntry.attachment4) {
            farmEntry.attachment4URL = await Storage.get(farmEntry.attachment4);
          }
          setAX1FarmEntry1(farmEntry);
        }

        if (labEntry.AX1FarmBatchID2) {
          const farmEntry2 = await loadFarmEntry(labEntry.AX1FarmBatchID2);
          if (farmEntry2.attachment) {
            farmEntry2.attachmentURL = await Storage.get(farmEntry2.attachment);
          }
          if (farmEntry2.attachment2) {
            farmEntry2.attachment2URL = await Storage.get(
              farmEntry2.attachment2
            );
          }
          if (farmEntry2.attachment3) {
            farmEntry2.attachment3URL = await Storage.get(
              farmEntry2.attachment3
            );
          }
          if (farmEntry2.attachment4) {
            farmEntry2.attachment4URL = await Storage.get(
              farmEntry2.attachment4
            );
          }
          setAX1FarmEntry2(farmEntry2);
        }

        if (labEntry.AX1FarmBatchID3) {
          const farmEntry3 = await loadFarmEntry(labEntry.AX1FarmBatchID3);
          if (farmEntry3.attachment) {
            farmEntry3.attachmentURL = await Storage.get(farmEntry3.attachment);
          }
          if (farmEntry3.attachment2) {
            farmEntry3.attachment2URL = await Storage.get(
              farmEntry3.attachment2
            );
          }
          if (farmEntry3.attachment3) {
            farmEntry3.attachment3URL = await Storage.get(
              farmEntry3.attachment3
            );
          }
          if (farmEntry3.attachment4) {
            farmEntry3.attachment4URL = await Storage.get(
              farmEntry3.attachment4
            );
          }
          setAX1FarmEntry3(farmEntry3);
        }

        setDoNotUse(labEntry.DONOTUSE);
        setYeastandMold1(labEntry.yeastAndMold1);
        setYeastandMold2(labEntry.yeastAndMold2);
        setYeastandMold3(labEntry.yeastAndMold3);
        // setYeastandMold4(labEntry.yeastAndMold4);
        setBarrelIDLinked(labEntry.BarrelIDLinked);
        setAX1FarmBatchID(labEntry.AX1FarmBatchID);
        setAX1FarmBatchID2(labEntry.AX1FarmBatchID2);
        setAX1FarmBatchID3(labEntry.AX1FarmBatchID3);
        setDateTestedDayMinus1(labEntry.DateTestedDayMinus1);
        setBacterialContColonyFormingUnits1(
          labEntry.BacterialContColonyFormingUnits1
        );
        setyeastAndMoldColonyFormingUnits1(
          labEntry.yeastAndMoldColonyFormingUnits1
        );
        setBacterialCont1(labEntry.BacterialCont1);
        setDateTestedDayMinus2(labEntry.DateTestedDayMinus2);
        setBacterialContColonyFormingUnits2(
          labEntry.BacterialContColonyFormingUnits2
        );
        setyeastAndMoldColonyFormingUnits2(
          labEntry.yeastAndMoldColonyFormingUnits2
        );
        setBacterialCont2(labEntry.BacterialCont2);
        setDateTestedDayMinus3(labEntry.DateTestedDayMinus3);
        setBacterialContColonyFormingUnits3(
          labEntry.BacterialContColonyFormingUnits3
        );
        setyeastAndMoldColonyFormingUnits3(
          labEntry.yeastAndMoldColonyFormingUnits3
        );
        setBacterialCont3(labEntry.BacterialCont3);
        // setDateTestedDayMinus4(labEntry.DateTestedDayMinus4);
        // setColonyFormingUnits4(labEntry.ColonyFormingUnits4);
        // setBacterialCont4(labEntry.BacterialCont4);
        setCellGrowthEfficiency(labEntry.CellGrowthEfficiency);
        setClottableProteinContent(labEntry.ClottableProteinContent);
        setTotalProtein(labEntry.TotalProtein);
        setLabTech(labEntry.LabTech);

        setNotes(labEntry.Notes);
        var attachment = labEntry.attachment;

        if (attachment) {
          labEntry.attachmentURL = await Storage.get(attachment);
        }
        setLabEntry(labEntry);
        setisActive(false);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return LabTech.length > 0;
  }

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  const onSwitchDoNotUse = () => {
    setDoNotUse(!DONOTUSE);
  };

  const onSwitchBacterialCont1 = () => {
    BacterialCont1 = !BacterialCont1;
    setBacterialCont1(BacterialCont1);
    checkDoNotUse();
  };

  const onSwitchBacterialCont2 = () => {
    BacterialCont2 = !BacterialCont2;
    setBacterialCont2(BacterialCont2);
    checkDoNotUse();
  };

  const onSwitchBacterialCont3 = () => {
    BacterialCont3 = !BacterialCont3;
    setBacterialCont3(BacterialCont3);
    checkDoNotUse();
  };

  // const onSwitchBacterialCont4 = () => {
  //   BacterialCont4 = !BacterialCont4;
  //   setBacterialCont4(BacterialCont4);
  //   checkDoNotUse();
  // };

  const onSwitchYeastandMold1 = () => {
    yeastAndMold1 = !yeastAndMold1;
    setYeastandMold1(yeastAndMold1);
    checkDoNotUse();
  };

  const onSwitchYeastandMold2 = () => {
    yeastAndMold2 = !yeastAndMold2;
    setYeastandMold2(yeastAndMold2);
    checkDoNotUse();
  };

  const onSwitchYeastandMold3 = () => {
    yeastAndMold3 = !yeastAndMold3;
    setYeastandMold3(yeastAndMold3);
    checkDoNotUse();
  };

  // const onSwitchYeastandMold4 = () => {
  //   yeastAndMold4 = !yeastAndMold4;
  //   setYeastandMold4(yeastAndMold4);
  //   checkDoNotUse();
  // };

  const onChangeCellGrowthHandler = (data) => {
    CellGrowthEfficiency = data;
    setCellGrowthEfficiency(CellGrowthEfficiency);
    checkDoNotUse();
  };

  const onChangeBacterialContColonyUnit1 = (data) => {
    BacterialContColonyFormingUnits1 = data;
    setBacterialContColonyFormingUnits1(BacterialContColonyFormingUnits1);
    checkDoNotUse();
  };

  const onChangeBacterialContColonyUnit2 = (data) => {
    BacterialContColonyFormingUnits2 = data;
    setBacterialContColonyFormingUnits2(BacterialContColonyFormingUnits2);
    checkDoNotUse();
  };

  const onChangeBacterialContColonyUnit3 = (data) => {
    BacterialContColonyFormingUnits3 = data;
    setBacterialContColonyFormingUnits3(BacterialContColonyFormingUnits3);
    checkDoNotUse();
  };

  const onChangeyeastAndMoldColonyUnit1 = (data) => {
    yeastAndMoldColonyFormingUnits1 = data;
    setyeastAndMoldColonyFormingUnits1(yeastAndMoldColonyFormingUnits1);
    checkDoNotUse();
  };

  const onChangeyeastAndMoldColonyUnit2 = (data) => {
    yeastAndMoldColonyFormingUnits2 = data;
    setyeastAndMoldColonyFormingUnits2(yeastAndMoldColonyFormingUnits2);
    checkDoNotUse();
  };

  const onChangeyeastAndMoldColonyUnit3 = (data) => {
    yeastAndMoldColonyFormingUnits3 = data;
    setyeastAndMoldColonyFormingUnits3(yeastAndMoldColonyFormingUnits3);
    checkDoNotUse();
  };

  // const onChangeColonyUnit4 = (data) => {
  //   ColonyFormingUnits4 = data;
  //   setColonyFormingUnits4(ColonyFormingUnits4);
  //   checkDoNotUse();
  // };

  function updateEntry(Item) {
    return API.put("omeat", `/LAB/entries/${encodeURIComponent(id)}`, {
      body: Item,
    });
  }

  function showChemResult(testName, results) {
    return (
      <>
        <Accordion className="scannerC mb-3">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{testName} - Test Results</Accordion.Header>
            <Accordion.Body>
              <Table responsive className="tableDiv">
                <tbody>
                  <tr>
                    <th>TestName</th>
                    <th>TestUnits</th>
                    <th>TestValue</th>
                    <th>RefRangeHigh</th>
                    <th>RefRangeLow</th>
                    <th>TestDate</th>
                  </tr>
                  {results.map((result, index) => (
                    <tr key={index}>
                      <td>{result.TestName}</td>
                      <td>{result.TestUnits}</td>
                      <td>{result.TestValue}</td>
                      <td>{result.RefRangeHigh}</td>
                      <td>{result.RefRangeLow}</td>
                      <td>{result.TestDate}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }

  function showFarm(AX1FarmEntry1) {
    return (
      <>
        <Accordion className="scannerC mb-3">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{AX1FarmEntry1.Title} </Accordion.Header>
            <Accordion.Body>
              <Table className="tableDiv">
                <tbody>
                  <tr>
                    <td>CowName</td>
                    <td className="fw-bold">{AX1FarmEntry1.CowName}</td>
                  </tr>
                  <tr>
                    <td>Collection Date</td>
                    <td className="fw-bold">{AX1FarmEntry1.CollectionDate}</td>
                  </tr>
                  <tr>
                    <td>Collection Volume</td>
                    <td className="fw-bold">
                      {AX1FarmEntry1.CollectionVolume}
                    </td>
                  </tr>
                  <tr>
                    <td>StartTimeCow </td>
                    <td className="fw-bold"> {AX1FarmEntry1.StartTimeCow}</td>
                  </tr>
                  <tr>
                    <td>StartTimeApheresis</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry1.StartTimeApheresis}
                    </td>
                  </tr>
                  <tr>
                    <td>EndTimeApheresis</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry1.EndTimeApheresis}
                    </td>
                  </tr>
                  <tr>
                    <td>EndTime (Cow Release)</td>
                    <td className="fw-bold"> {AX1FarmEntry1.EndTimeCow}</td>
                  </tr>
                  <tr>
                    <td>MachineErrors</td>
                    <td className="fw-bold"> {AX1FarmEntry1.MachineErrors}</td>
                  </tr>
                  <tr>
                    <td>NewWashedLine</td>
                    <td className="fw-bold"> {AX1FarmEntry1.NewWashedLine}</td>
                  </tr>
                  <tr>
                    <td>CatheterPosition</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry1.CatheterPosition}
                    </td>
                  </tr>
                  <tr>
                    <td>Notes</td>
                    <td className="fw-bold"> {AX1FarmEntry1.Notes}</td>
                  </tr>
                  <tr>
                    <td>LeadTech</td>
                    <td className="fw-bold"> {AX1FarmEntry1.LeadTech}</td>
                  </tr>
                  <tr>
                    <td>Attachment</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry1.attachment && (
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={AX1FarmEntry1.attachmentURL}
                          >
                            {formatFilename(AX1FarmEntry1.attachment)}
                          </a>
                        </p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Attachment2</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry1.attachment2 && (
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={AX1FarmEntry1.attachment2URL}
                          >
                            {formatFilename(AX1FarmEntry1.attachment2)}
                          </a>
                        </p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Attachment3</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry1.attachment3 && (
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={AX1FarmEntry1.attachment3URL}
                          >
                            {formatFilename(AX1FarmEntry1.attachment3)}
                          </a>
                        </p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Attachment4</td>
                    <td className="fw-bold">
                      {" "}
                      {AX1FarmEntry1.attachment4 && (
                        <p>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={AX1FarmEntry1.attachment4URL}
                          >
                            {formatFilename(AX1FarmEntry1.attachment4)}
                          </a>
                        </p>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {AX1FarmEntry1.chemResults &&
                showChemResult("Chemistry ", AX1FarmEntry1.chemResults)}
              {AX1FarmEntry1.CBCResults &&
                showChemResult("CBC ", AX1FarmEntry1.CBCResults)}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      console.log("File size error");
      return;
    }
    setIsLoading(true);
    const attachment = file.current
      ? await s3Upload(file.current)
      : LabEntry.attachment;

    await updateEntry({
      DateTestedDayMinus1,
      BacterialContColonyFormingUnits1,
      yeastAndMoldColonyFormingUnits1,
      BacterialCont1,
      DateTestedDayMinus2,
      BacterialContColonyFormingUnits2,
      yeastAndMoldColonyFormingUnits2,
      BacterialCont2,
      DateTestedDayMinus3,
      BacterialContColonyFormingUnits3,
      yeastAndMoldColonyFormingUnits3,
      BacterialCont3,
      // DateTestedDayMinus4,
      // ColonyFormingUnits4,
      // BacterialCont4,
      ClottableProteinContent,
      CellGrowthEfficiency,
      DONOTUSE,
      yeastAndMold1,
      yeastAndMold2,
      yeastAndMold3,
      // yeastAndMold4,
      LabTech,
      TotalProtein,
      attachment,
      Notes,
      AX1FarmBatchID,
      AX1FarmBatchID2,
      AX1FarmBatchID3,
      BarrelIDLinked,
    });

    setIsLoading(false);
    history.push("/lab");
  }

  const checkDoNotUse = () => {
    if (
      BacterialCont1 ||
      BacterialCont2 ||
      BacterialCont3 ||
      // BacterialCont4 ||
      yeastAndMold1 ||
      yeastAndMold2 ||
      yeastAndMold3 ||
      // yeastAndMold4 ||
      BacterialContColonyFormingUnits1 > 0 ||
      yeastAndMoldColonyFormingUnits1 > 0 ||
      BacterialContColonyFormingUnits2 > 0 ||
      yeastAndMoldColonyFormingUnits2 > 0 ||
      BacterialContColonyFormingUnits3 > 0 ||
      yeastAndMoldColonyFormingUnits3 > 0 ||
      // ColonyFormingUnits4 > 0 ||
      (CellGrowthEfficiency !== "" && CellGrowthEfficiency < 40)
    ) {
      DONOTUSE = true;
      setDoNotUse(DONOTUSE);
    } else if (
      BacterialCont1 ||
      BacterialCont2 ||
      BacterialCont3 ||
      // BacterialCont4 ||
      yeastAndMold1 ||
      yeastAndMold2 ||
      yeastAndMold3 ||
      // yeastAndMold4 ||
      BacterialContColonyFormingUnits1 > 0 ||
      yeastAndMoldColonyFormingUnits1 > 0 ||
      BacterialContColonyFormingUnits2 > 0 ||
      yeastAndMoldColonyFormingUnits2 > 0 ||
      BacterialContColonyFormingUnits3 > 0 ||
      yeastAndMoldColonyFormingUnits3 > 0 ||
      // ColonyFormingUnits4 > 0 ||
      CellGrowthEfficiency > 40
    ) {
      DONOTUSE = false;
      setDoNotUse(DONOTUSE);
    } else if (
      !BacterialCont1 ||
      !BacterialCont2 ||
      !BacterialCont3 ||
      // !BacterialCont4 ||
      !yeastAndMold1 ||
      !yeastAndMold2 ||
      !yeastAndMold3 ||
      // !yeastAndMold4 ||
      BacterialContColonyFormingUnits1 === "" ||
      BacterialContColonyFormingUnits2 === "" ||
      BacterialContColonyFormingUnits3 === "" ||
      yeastAndMoldColonyFormingUnits1 === "" ||
      yeastAndMoldColonyFormingUnits2 === "" ||
      yeastAndMoldColonyFormingUnits3 === "" ||
      // ColonyFormingUnits4 === "" ||
      CellGrowthEfficiency === "" ||
      CellGrowthEfficiency > 40
    ) {
      DONOTUSE = false;
      setDoNotUse(DONOTUSE);
    }
  };

  return (
    <>
      <LoadingOverlay active={isActive} spinner text="Loading...">
        <div className="shadow-lg p-3 mb-2 bg-white rounded px-2 mt-2">
          {1 && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="BarrelIDLinked">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Barrel ID"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Control
                    placeholder="Barrel ID"
                    disabled
                    type="text"
                    value={BarrelIDLinked}
                  />
                </FloatingLabel>
              </Form.Group>
              <Container className="scannerC">
                <Row>
                  <Col className="scanner">
                    <Form.Group controlId="AX1FarmBatchID">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="AX1FarmBatchID"
                        className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                      >
                        <Form.Control
                          placeholder="AX1FarmBatchID"
                          type="text"
                          disabled
                          value={AX1FarmBatchID}
                          onChange={(e) => setAX1FarmBatchID(e.target.value)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col xs={2} md={1}>
                    <button
                      disabled
                      type="button"
                      onClick={() => handleShow(1)}
                    >
                      <BsUpcScan size={15} />
                    </button>
                  </Col>
                </Row>
                {AX1FarmEntry1 && showFarm(AX1FarmEntry1)}
              </Container>
              <Container className="scannerC">
                <Row>
                  <Col className="scanner">
                    <Form.Group controlId="AX1FarmBatchID2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="AX1FarmBatchID2"
                        className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                      >
                        <Form.Control
                          placeholder="AX1FarmBatchID2"
                          disabled
                          type="text"
                          value={AX1FarmBatchID2}
                          onChange={(e) => setAX1FarmBatchID2(e.target.value)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col xs={2} md={1}>
                    <button
                      disabled
                      type="button"
                      onClick={() => handleShow(2)}
                    >
                      <BsUpcScan size={15} />
                    </button>
                  </Col>
                </Row>
                {AX1FarmEntry2 && showFarm(AX1FarmEntry2)}
              </Container>
              <Container className="scannerC">
                <Row>
                  <Col className="scanner">
                    <Form.Group controlId="AX1FarmBatchID2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="AX1FarmBatchID3"
                        className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                      >
                        <Form.Control
                          placeholder="AX1FarmBatchID3"
                          disabled
                          type="text"
                          value={AX1FarmBatchID3}
                          onChange={(e) => setAX1FarmBatchID3(e.target.value)}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col xs={2} md={1}>
                    <button
                      disabled
                      type="button"
                      onClick={() => handleShow(3)}
                    >
                      <BsUpcScan size={15} />
                    </button>
                  </Col>
                </Row>
                {AX1FarmEntry3 && showFarm(AX1FarmEntry3)}
              </Container>
              <Form.Group className="mt-3" controlId="DONOTUSE">
                <Form.Check
                  type="switch"
                  id="donotuse"
                  label="DO NOT USE"
                  checked={DONOTUSE}
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  onChange={onSwitchDoNotUse}
                />
              </Form.Group>
              <fieldset className="fieldSetCustom">
                <legend>Day 1</legend>
                <Form.Group controlId="DateTestedDayMinus1">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Date Tested (Test 1)"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      type="date"
                      name="DateTestedDayMinus1"
                      value={DateTestedDayMinus1}
                      placeholder="DateTestedDayMinus1"
                      onChange={(e) => setDateTestedDayMinus1(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mt-3" controlId="BacterialCont1">
                  <Form.Check
                    type="switch"
                    id="BacterialCont1"
                    label="BacterialCont1?"
                    checked={BacterialCont1}
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                    onChange={onSwitchBacterialCont1}
                  />
                </Form.Group>

                {BacterialCont1 && <Form.Group controlId="BacterialContColonyFormingUnits1">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="BacterialCont Colony Forming Units 1"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      placeholder="BacterialContColonyFormingUnits1"
                      value={BacterialContColonyFormingUnits1}
                      type="text"
                      // disabled={!BacterialCont1}
                      onChange={(e) =>
                        onChangeBacterialContColonyUnit1(e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Form.Group>}
                <Form.Group className="mt-3" controlId="yeastAndMold1">
                  <Form.Check
                    type="switch"
                    id="yeastAndMold1"
                    label="Yeast and Mold 1?"
                    checked={yeastAndMold1}
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                    onChange={onSwitchYeastandMold1}
                  />
                </Form.Group>
                {yeastAndMold1 && <Form.Group controlId="yeastAndMoldColonyFormingUnits1">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Yeast and Mold Colony Forming Units 1"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      placeholder="yeastAndMoldColonyFormingUnits1"
                      value={yeastAndMoldColonyFormingUnits1}
                      type="text"
                      // disabled={!yeastAndMold1}
                      onChange={(e) =>
                        onChangeyeastAndMoldColonyUnit1(e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Form.Group>}
              </fieldset>
              <fieldset className="fieldSetCustom">
                <legend>Day 5</legend>
                <Form.Group controlId="DateTestedDayMinus2">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Date tested (Day 5) AX1 and AX1+Media(50%)"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      type="date"
                      name="DateTestedDayMinus2"
                      value={DateTestedDayMinus2}
                      placeholder="DateTestedDayMinus2"
                      onChange={(e) => setDateTestedDayMinus2(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mt-3" controlId="BacterialCont2">
                  <Form.Check
                    type="switch"
                    id="BacterialCont2"
                    label="BacterialCont2?"
                    checked={BacterialCont2}
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                    onChange={onSwitchBacterialCont2}
                  />
                </Form.Group>

                {BacterialCont2 && <Form.Group controlId="BacterialContColonyFormingUnits2">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="BacterialCont Colony Forming Units 2"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      placeholder="BacterialContColonyFormingUnits2"
                      value={BacterialContColonyFormingUnits2}
                      type="text"
                      // disabled={!BacterialCont2}
                      onChange={(e) =>
                        onChangeBacterialContColonyUnit2(e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Form.Group>}

                <Form.Group className="mt-3" controlId="yeastAndMold2">
                  <Form.Check
                    type="switch"
                    id="yeastAndMold2"
                    label="Yeast and Mold 2?"
                    checked={yeastAndMold2}
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                    onChange={onSwitchYeastandMold2}
                  />
                </Form.Group>
                {yeastAndMold2 && <Form.Group controlId="yeastAndMoldColonyFormingUnits2">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Yeast and Mold Colony Forming Units 2"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      placeholder="yeastAndMoldColonyFormingUnits2"
                      value={yeastAndMoldColonyFormingUnits2}
                      // disabled={!yeastAndMold2}
                      type="text"
                      onChange={(e) =>
                        onChangeyeastAndMoldColonyUnit2(e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Form.Group>}
              </fieldset>
              <fieldset className="fieldSetCustom">
                <legend>Day 14</legend>
                <Form.Group controlId="DateTestedDayMinus3">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Date Tested (Day 14)"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      type="date"
                      name="DateTestedDayMinus3"
                      value={DateTestedDayMinus3}
                      placeholder="DateTestedDayMinus3"
                      onChange={(e) => setDateTestedDayMinus3(e.target.value)}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mt-3" controlId="BacterialCont3">
                  <Form.Check
                    type="switch"
                    id="BacterialCont3"
                    label="BacterialCont3?"
                    checked={BacterialCont3}
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                    onChange={onSwitchBacterialCont3}
                  />
                </Form.Group>
                {BacterialCont3 && <Form.Group controlId="BacterialContColonyFormingUnits3">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="BacterialCont Colony Forming Units 3"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      placeholder="BacterialContColonyFormingUnits3"
                      value={BacterialContColonyFormingUnits3}
                      type="text"
                      // disabled={!BacterialCont3}
                      onChange={(e) =>
                        onChangeBacterialContColonyUnit3(e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Form.Group>}
                <Form.Group className="mt-3" controlId="yeastAndMold3">
                  <Form.Check
                    type="switch"
                    id="yeastAndMold3"
                    label="Yeast and Mold 3?"
                    checked={yeastAndMold3}
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                    onChange={onSwitchYeastandMold3}
                  />
                </Form.Group>
                {yeastAndMold3 && <Form.Group controlId="yeastAndMoldColonyFormingUnits3">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Yeast and Mold Colony Forming Units 3"
                    className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  >
                    <Form.Control
                      placeholder="yeastAndMoldColonyFormingUnits3"
                      value={yeastAndMoldColonyFormingUnits3}
                      // disabled={!yeastAndMold3}
                      type="text"
                      onChange={(e) =>
                        onChangeyeastAndMoldColonyUnit3(e.target.value)
                      }
                    />
                  </FloatingLabel>
                </Form.Group>}
              </fieldset>
              {/* <Form.Group controlId="DateTestedDayMinus4">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Date Tested (Test 4)"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Control
                    type="date"
                    name="DateTestedDayMinus4"
                    value={DateTestedDayMinus4}
                    placeholder="DateTestedDayMinus4"
                    onChange={(e) => setDateTestedDayMinus4(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mt-3" controlId="BacterialCont4">
                <Form.Check
                  type="switch"
                  id="BacterialCont4"
                  label="BacterialCont4?"
                  checked={BacterialCont4}
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  onChange={onSwitchBacterialCont4}
                />
              </Form.Group>
              <Form.Group className="mt-3" controlId="yeastAndMold4">
                <Form.Check
                  type="switch"
                  id="yeastAndMold4"
                  label="Yeast and Mold 4?"
                  checked={yeastAndMold4}
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                  onChange={onSwitchYeastandMold4}
                />
              </Form.Group>
              <Form.Group controlId="ColonyFormingUnits4">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Colony Forming Units"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Control
                    placeholder="ColonyFormingUnits4"
                    value={ColonyFormingUnits4}
                    type="text"
                    onChange={(e) => onChangeColonyUnit4(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group controlId="ClottableProteinContent">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Clottable Protein Content"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Control
                    placeholder="Clottable Protein Content"
                    type="text"
                    value={ClottableProteinContent}
                    onChange={(e) => setClottableProteinContent(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="TotalProtein">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Total Protein"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Control
                    placeholder="TotalProtein"
                    type="text"
                    value={TotalProtein}
                    onChange={(e) => setTotalProtein(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="CellGrowthEfficiency">
                <FloatingLabel
                  controlId="floatingInput"
                  label="CellGrowthEfficiency (%)"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Control
                    placeholder="CellGrowthEfficiency"
                    type="text"
                    value={CellGrowthEfficiency}
                    onChange={(e) => onChangeCellGrowthHandler(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="LabTech">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lab Tech"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Select
                    className="mt-3"
                    value={LabTech}
                    aria-label="Default select example"
                    onChange={(e) => setLabTech(e.target.value)}
                  >
                    <option> </option>
                    <option value="Mona">Mona</option>
                    <option value="AliD">AliD</option>
                    <option value="Praveen">Praveen</option>
                    <option value="Reza">Reza</option>
                    <option value="Arshia">Arshia</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="Notes">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Notes"
                  className={DONOTUSE ? "doNotUseError mb-3" : "mb-3"}
                >
                  <Form.Control
                    placeholder="Notes"
                    type="text"
                    value={Notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="file">
                <Form.Label>Attachment</Form.Label>
                {LabEntry && LabEntry.attachment && (
                  <p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={LabEntry.attachmentURL}
                    >
                      {formatFilename(LabEntry.attachment)}
                    </a>
                  </p>
                )}
                <Form.Control onChange={handleFileChange} type="file" />
              </Form.Group>
              <LoaderButton
                block="true"
                type="submit"
                size="lg"
                variant="primary"
                isLoading={isLoading}
                className={DONOTUSE ? "doNotUseError format" : "format"}
                disabled={!validateForm()}
              >
                Update
              </LoaderButton>
            </Form>
          )}
          <Modal
            show={QRshow}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Scanner
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={async (result, error) => {
                  if (!!result) {
                    await loadScannedEntry(result.text);
                    //handleClose();
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
                // style={{ width: '100%' }}
              />
            </Modal.Body>
            <Modal.Footer>
              {scannerErrorMessage ? (
                <div className="justify-content-center alert alert-danger">
                  Scanned Item doesn't exist in the database
                </div>
              ) : null}
            </Modal.Footer>
          </Modal>
        </div>
      </LoadingOverlay>
    </>
  );
}
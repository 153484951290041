import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";
import "./ShipHome.css";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Button, Modal, Accordion } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import { useHistory } from "react-router-dom";

export default function ShipHome() {
  const [entries, setEntries] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showonlyUserEntry, setshowonlyUserEntry] = useState(true);
  const [showonlyPendingEntry, setshowonlyPendingEntry] = useState(true);
  const [QRshow, setQRshow] = useState(false);
  const [scannerErrorMessage, setscannerErrorMessage] = useState(false);
  const handleClose = () => setQRshow(false);


  const history = useHistory();

  async function loadScannedEntry(QRCodeLinked) {
    console.log("QRCode:" + QRCodeLinked);
    if (QRCodeLinked) {
      console.log("scanned! now check if it exists ");
      const scannedID = await API.get("omeat", `/scan/entries/${QRCodeLinked}`);
      if (scannedID.length > 0) {
        if (scannedID[0].pk && scannedID[0].pk.includes("BARREL")) {
          let path = `/farm/barrel/${encodeURIComponent(scannedID[0].sk)}`;
          history.push(path);
        }
      } else {
        setscannerErrorMessage(true);
      }
    }
  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const entries = await loadDefUserEntries();
        setEntries(entries);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  async function loadDefUserEntries() {
    return API.get("omeat", "/SHIP/entriesuser?pending=Y");
  }

  async function loadUserEntries(showonlyPendingEntry) {
    return API.get("omeat", "/SHIP/entriesuser?pending=" + showonlyPendingEntry);
  }

  function loadAllEntries(showonlyPendingEntry) {
    return API.get("omeat", "/SHIP/entriesall?pending=" + showonlyPendingEntry);
  }
  const filterResults = async (event) => {
    event.preventDefault();
    try {
      console.log("showonlyUserEntry:" + showonlyUserEntry);
      if (showonlyUserEntry) {
        var entries = await loadUserEntries(showonlyPendingEntry?"Y":"N");
      } else {
        entries = await loadAllEntries(showonlyPendingEntry?"Y":"N");
      }

      setEntries(entries);
      //setshowonlyUserEntry(!showonlyUserEntry);
    } catch (e) {
      onError(e);
    }
    console.log("now calling API");
  };

  function renderEntriesList(entries) {
    return (
      <>
        <LinkContainer to="/farm/shipping/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">
              Create a new Ship entry
            </span>
          </ListGroup.Item>
        </LinkContainer>
        {entries.map(
          ({
            sk,
            shipTime,
            FarmBatchIDs,
            shipDate,
            createdAt,
          }) => (
              <LinkContainer
                key={sk}
                to={`/farm/shipping/${encodeURIComponent(sk)}`}
              >
                <ListGroup.Item action>
                  <span className="font-weight-bold listwrap">{sk}</span>
                  <br />
                  <span className="text-muted">
                    Shipping Date: {shipDate}, Shipping Time : {shipTime}, Created: {" "} {createdAt.length > 0
                      ? new Date(createdAt).toLocaleDateString() +
                      " " +
                      new Date(createdAt).toLocaleTimeString()
                      : ""}
                  </span>
                </ListGroup.Item>
              </LinkContainer>
            )
        )}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Omeat</h1>
        <LinkContainer className="font-color" to="/login">
          <Button className="mt-3 format">Login</Button>
        </LinkContainer>
      </div>
    );
  }

  function handleCheck() {
    setshowonlyUserEntry(!showonlyUserEntry);
  }

  function handleCheck2() {
    setshowonlyPendingEntry(!showonlyPendingEntry);
  }

  function renderEntries() {
    return (
      <div className="entries">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Shipping Entries </h2>
        <Accordion className="mb-3" defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filters </Accordion.Header>
            <Accordion.Body>
              <div className="filters mt-1 mb-5 border border-primary rounded">
                <div className="mt-3 mb-3 mx-3">
                  <Form className onSubmit={filterResults}>
                    <Form.Check
                      type="switch"
                      id="showonlyUserEntry"
                      label="Show only my entries?"
                      className="mb-3"
                      defaultChecked={showonlyUserEntry}
                      onChange={handleCheck}
                    />
                    <Form.Check
                      type="switch"
                      id="showonlyPendingEntry"
                      label="Show only entries not received yet?"
                      className="mb-3"
                      defaultChecked={showonlyPendingEntry}
                      onChange={handleCheck2}
                    />
                    <LoaderButton
                      block="true"
                      type="submit"
                      size="sm"
                      variant="primary"
                      isLoading={isLoading}
                      className="format"
                    >
                      Apply
                    </LoaderButton>
                  </Form>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <ListGroup>{!isLoading && renderEntriesList(entries)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderEntries() : renderLander()}
      <Modal
        show={QRshow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={async (result, error) => {
              if (!!result) {
                await loadScannedEntry(result.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </Modal.Body>
        <Modal.Footer>
          {scannerErrorMessage ? (
            <div className="alert alert-danger">
              Scanned Item doesn't exist in the database
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
